import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";

const OrderSuccess = () => {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    // Cleanup the interval when component unmounts
    return () => clearInterval(countdownInterval);
  }, []);

  useEffect(() => {
    // Redirect when countdown reaches 0
    if (countdown === 0) {
      navigate("/history");
    }
  }, [countdown]);

  return (
    <>
      <div className="tf-container">
        <div className="alert-container success">
          <i className="bx bx-check-circle"></i>
          <h4 className="title">Pesanan Berhasil Dibuat</h4>
          <p className="desc">
            Anda akan diarahkan ke halaman riwayat pesanan dalam
          </p>

          <div className="countdown">
            <span id="countdown">{countdown}</span> detik
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderSuccess;
