// DetailProductCard.js
import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { updateCart } from "../../redux/actions/cartActions";

const DetailProductCart = ({ menu }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const carts = useSelector((state) => state.cart.carts);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    const existingCartItem = carts.find((item) => item.id === menu?.id);
    if (existingCartItem) {
      setQuantity(existingCartItem.qty);
    } else {
      // If the menu item is not in the cart, set the quantity to 0
      setQuantity(0);
    }
  }, [carts, menu]);
  const removeFromCart = () => {
    const updatedCarts = carts.filter((item) => item.id !== menu?.id);
    dispatch(updateCart(updatedCarts));
    setQuantity(0);
  };

  const increaseCartQty = () => {
    const newQty = quantity + 1;

    if (menu && menu.quantity < newQty && !menu.isUnlimited) {
      addToast(`Sisa stok hanya ${menu.quantity}`, {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setQuantity(newQty);
    updateCartQty(newQty);
  };

  const decreaseCartQty = () => {
    const newQty = quantity - 1;
    setQuantity(newQty >= 0 ? newQty : 0);

    if (newQty <= 0) {
      removeFromCart();
    } else {
      updateCartQty(newQty);
    }
  };

  const updateCartQty = (qty) => {
    // Find if the menu item is already in the cart
    const existingCartItem = carts.find((item) => item.id === menu.id);

    if (existingCartItem) {
      // Update the quantity if the menu item is already in the cart
      const updatedCarts = carts.map((item) =>
        item.id === menu.id ? { ...item, qty: qty } : item
      );
      dispatch(updateCart(updatedCarts));
    } else if (qty > 0) {
      // Add a new cart item if the menu item is not in the cart and the quantity is greater than 0
      const newCartItem = {
        id: menu.id,
        name: menu.name,
        price: menu.price,
        qty: qty,
      };
      dispatch(updateCart([...carts, newCartItem]));
    }
  };

  const setCartQty = (event) => {
    const newQty = event.target.value.replace(/\D/g, "");
    const numericValue = parseInt(newQty, 10);

    if (isNaN(numericValue)) {
      setQuantity(0);
      updateCartQty(0);
      return;
    }

    if (menu && menu.quantity < numericValue && !menu.isUnlimited) {
      addToast(`Sisa stok hanya ${menu.quantity}`, {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setQuantity(numericValue);
    updateCartQty(numericValue);
  };

  return (
    <div className="cart-container mt-4">
      <div className="title">Tambahkan ke Keranjang:</div>

      <div className="action-container">
        <div className="cart ">
          <button className="btn btn-control-cart" onClick={decreaseCartQty}>
            -
          </button>
          <input
            type="text"
            value={quantity}
            onChange={setCartQty}
            className="form-control form-control-sm"
            min={0}
          />
          <button className="btn btn-control-cart" onClick={increaseCartQty}>
            +
          </button>
        </div>

        {quantity > 0 && (
          <button
            className="btn d-flex flex-row justify-content-end mt-1"
            onClick={removeFromCart}
          >
            <i className="bx bx-trash text-danger"></i>
          </button>
        )}
      </div>
      <div className="quantity">
        Stok : {menu?.isUnlimited ? "Tersedia" : menu?.quantity}
      </div>
    </div>
  );
};

export default DetailProductCart;
