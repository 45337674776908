// OtpVerification.jsx
import React, { useState, useEffect } from "react";
import axios from "axios";
import CountdownTimer from "./CountdownTimer";
import { useToasts } from "react-toast-notifications";
import { useNavigate } from "react-router-dom";

const OtpVerification = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpExpiredDate, setOtpExpiredDate] = useState("");
  const [otpCode, setOtpCode] = useState("");
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [loadingResend, setLoadingResend] = useState(false);

  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem("phoneNumber");
    if (storedPhoneNumber) {
      setPhoneNumber(storedPhoneNumber);
    }

    const storedOtpExpiredDate = localStorage.getItem("otpExpiredDate");
    if (storedOtpExpiredDate) {
      setOtpExpiredDate(storedOtpExpiredDate);
      setIsCountdownActive(true);
    }
  }, []);

  const handleRequestNewOtp = async () => {
    try {
      setLoadingResend(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/signin`,
        { phone_number: `${phoneNumber}` }
      );

      setOtpExpiredDate(response.data.expired_date);
      localStorage.setItem("otpExpiredDate", response.data.expired_date);

      addToast(response.data.message, {
        appearance: response.data.status ? "success" : "error",
        autoDismiss: true,
      });

      setIsCountdownActive(true);
    } catch (error) {
      console.error("Error requesting new OTP:", error);
      addToast("Terjadi kesalahan saat mengirim OTP ulang", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoadingResend(false);
    }
  };

  const handleCountdownFinish = () => {
    setIsCountdownActive(false);
  };

  const handleVerificationSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoadingVerify(true);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/signin/verify_otp`,
        { phone_number: `${phoneNumber}`, otp_code: otpCode }
      );

      console.log("OTP VERIF:", response);

      addToast(response.data.message, {
        appearance: response.data.status ? "success" : "error",
        autoDismiss: true,
      });

      if (response.data.status) {
        handleSuccessEvent(response.data.data);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      addToast("Terjadi kesalahan saat verifikasi OTP", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      setLoadingVerify(false);
    }
  };

  const handleSuccessEvent = (user) => {
    // Remove localStorage items
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("otpExpiredDate");

    // Encode user as JSON and save to localStorage
    localStorage.setItem("user", JSON.stringify(user));

    // Redirect to "/"
    navigate("/");
  };

  return (
    <div className="tf-container" style={{ marginTop: "60px" }}>
      <form onSubmit={handleVerificationSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="phoneNumber">Nomor Whatsapp</label>
          <input
            type="text"
            className="form-control"
            name="phoneNumber"
            value={phoneNumber}
            readOnly
          />
        </div>

        <div className="form-group mb-3">
          <label htmlFor="otpCode">Kode Verifikasi OTP</label>
          <input
            type="text"
            className="form-control"
            name="otpCode"
            value={otpCode}
            onChange={(e) => setOtpCode(e.target.value)}
          />
        </div>

        {isCountdownActive && (
          <CountdownTimer
            formattedOtpExpiredDate={otpExpiredDate
              .replace(/\./g, ":")
              .replace("WIB", "+07:00")}
            onCountdownFinish={handleCountdownFinish}
          />
        )}

        <button
          type="button"
          className="btn btn-action-order btn-danger mt-5"
          onClick={handleVerificationSubmit}
          disabled={loadingVerify}
        >
          {loadingVerify ? "Memverifikasi..." : "Verifikasi OTP"}
        </button>

        {!isCountdownActive && (
          <button
            type="button"
            onClick={handleRequestNewOtp}
            className="btn btn-link btn-resend-otp text-danger"
            disabled={loadingResend}
          >
            {loadingResend
              ? "Mengirim ulang..."
              : "Kirim Kode Verifikasi Ulang"}
          </button>
        )}
      </form>
    </div>
  );
};

export default OtpVerification;
