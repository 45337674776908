import React, { useState } from "react";
import { useForm } from "react-hook-form";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useToasts } from "react-toast-notifications";

const FormRegisterSales = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);

  const uploadKtpImage = async (imageFile) => {
    try {
      const data = new FormData();
      data.append("location_image", imageFile);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/images/order/location/`,
        data
      );

      // console.log("Image Upload Response:", response.data);
      return response.data.data.file;

      // Lakukan sesuatu dengan respons, jika diperlukan
    } catch (error) {
      console.error("Image Upload Error:", error);
      throw error; // Anda dapat menangani error lebih lanjut atau melemparkannya ke tingkat yang lebih tinggi
    }
  };

  const register_sales = async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/commerce/sales/register`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Order Creation Error:", error);
      throw error; // Anda dapat menangani error lebih lanjut atau melemparkannya ke tingkat yang lebih tinggi
    }
  };

  const onSubmit = async (data) => {
    try {
      setLoading(true); // Set loading menjadi true saat proses pengiriman data
      const formData = getValues();
      const ktp_image = await uploadKtpImage(formData.ktp);

      const storedUser = JSON.parse(localStorage.getItem("user"));
      const sales_data = {
        ...formData,
        ktp: ktp_image,
        user_id: storedUser.user_id,
      };

      const register = await register_sales(sales_data); //  update_user_data(user.user_id);
      if (register.status === "SUCCESS") {
        localStorage.setItem("user", JSON.stringify(register.data));
      }

      addToast("Pendaftaran Sales berhasil!", {
        appearance: "success",
        autoDismiss: true,
      });
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
        addToast("Pendaftaran Sales gagal! Sales sudah terdaftar", {
          appearance: "error",
          autoDismiss: true,
        });
      }
      console.error("Error submitting form:", error.status);
    } finally {
      navigate("/profile");
      setLoading(false); // Set loading menjadi false setelah proses selesai, baik berhasil atau gagal
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setValue("ktp", file);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageHeader title={"Form Pendaftaran Sales"} />

      <div className="row">
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label htmlFor="nik">NIK</label>
            <input
              type="text"
              pattern="[0-9]*"
              className={`form-control ${errors.nik ? "is-invalid" : ""}`}
              {...register("nik", {
                required: "NIK harus diisi",
                pattern: {
                  value: /^[0-9]*$/,
                  message: "NIK hanya boleh berisi angka",
                },
              })}
            />
            {errors.nik && (
              <div className="invalid-feedback">{errors.nik.message}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label htmlFor="ktp">KTP (File Gambar)</label>
            <input
              type="file"
              className={`form-control ${errors.ktp ? "is-invalid" : ""}`}
              onChange={handleImageChange}
            />
            {errors.ktp && (
              <div className="invalid-feedback">{errors.ktp.message}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label htmlFor="bankName">Nama Bank</label>
            <input
              type="text"
              className={`form-control ${
                errors["bank_info.name"] ? "is-invalid" : ""
              }`}
              {...register("bank_info.name", {
                required: "Nama bank harus diisi",
              })}
            />
            {errors["bank_info.name"] && (
              <div className="invalid-feedback">
                {errors["bank_info.name"].message}
              </div>
            )}
          </div>

          <div className="form-group mb-3">
            <label htmlFor="holderName">Nama Pemilik Rekening</label>
            <input
              type="text"
              className={`form-control ${
                errors["bank_info.holder_name"] ? "is-invalid" : ""
              }`}
              {...register("bank_info.holder_name", {
                required: "Nama pemilik rekening harus diisi",
              })}
            />
            {errors["bank_info.holder_name"] && (
              <div className="invalid-feedback">
                {errors["bank_info.holder_name"].message}
              </div>
            )}
          </div>

          <div className="form-group mb-5">
            <label htmlFor="bankNumber">Nomor Rekening Bank</label>
            <input
              type="text"
              pattern="[0-9]*"
              className={`form-control ${
                errors["bank_info.number"] ? "is-invalid" : ""
              }`}
              {...register("bank_info.number", {
                required: "Nomor rekening bank harus diisi",
                pattern: {
                  value: /^[0-9]*$/,
                  message: "Nomor rekening bank hanya boleh berisi angka",
                },
              })}
            />
            {errors["bank_info.number"] && (
              <div className="invalid-feedback">
                {errors["bank_info.number"].message}
              </div>
            )}
          </div>

          <div className="form-group mb-3">
            <button
              type="submit"
              className="btn btn-action-order btn-danger"
              disabled={loading} // Disable the button if the form is in a loading state
            >
              {loading ? "Sedang Memproses..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default FormRegisterSales;
