import React from "react";
import { formatPrice, formattedDate } from "../../../helpers/AppHelpers";
import { useNavigate } from "react-router-dom";

const OrderMenu = ({ order, orderPayment }) => {
  const navigate = useNavigate();

  const calculateTotalDiscount = (menu) => {
    const totalDiscount = menu.reduce((acc, item) => acc + item.discount, 0);
    return totalDiscount;
  };

  return (
    <div className="card card-detail mb-4">
      <div className="card-body">
        <h5 className="title">Rincian Item</h5>

        <table className="table table-item-list">
          <tbody>
            {order.menu.map((item) => (
              <tr key={item.id}>
                <td className="qty">{item.quantity}x</td>
                <td className="name">{item.name}</td>
                <td className="total">
                  {formatPrice(item.price)}
                  {item.discount > 0 && (
                    <div className="diskon">- {formatPrice(item.discount)}</div>
                  )}
                </td>
              </tr>
            ))}

            {orderPayment.status === null &&
              orderPayment.history.length <= 0 && (
                <>
                  <tr>
                    <td colSpan={2} className="name lg">
                      Total Pembayaran
                    </td>
                    <td className="total lg">{formatPrice(order.total)}</td>
                  </tr>
                  {order.payment.status === null && (
                    <tr>
                      <td colSpan={3}>
                        <button
                          className="btn btn-action-order btn-danger"
                          onClick={() => {
                            navigate(`/payment/${orderPayment.order_id}`);
                          }}
                        >
                          Lakukan Pembayaran
                        </button>
                      </td>
                    </tr>
                  )}
                </>
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderMenu;
