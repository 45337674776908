import React from "react";

const PaymentStatusBadge = ({ status }) => {
  let badge;

  if (status === "partiallyPaid") {
    badge = <div className="badge warning sm">Dibayar Sebagian</div>;
  } else if (status === "pending") {
    badge = <div className="badge warning sm">Bayar Nanti</div>;
  } else if (status === "paid") {
    badge = <div className="badge success sm">Lunas</div>;
  } else if (status === "TOP") {
    badge = <div className="badge warning sm">Perjanjian Bayar</div>;
  } else if (status === "waiting_payment") {
    badge = <div className="badge warning sm">Menunggu Pembayaran</div>;
  } else if (status === "waiting_confirmation") {
    badge = <div className="badge info sm">Menunggu Verifikasi</div>;
  } else if (status === "approved") {
    badge = <div className="badge success sm">Terverifikasi</div>;
  } else if (status === "rejected") {
    badge = <div className="badge danger sm">Ditolak</div>;
  } else {
    badge = <div className="badge secondary sm">Belum Ada Pembayaran</div>;
  }

  return badge;
};

export default PaymentStatusBadge;
