import axios from "axios";
import React, { useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const PaymentCreditForm = ({ order_id, payment_id }) => {
  const [formData, setFormData] = useState({
    payment_method: "",
    amount: "",
    receipt: null,
  });

  const [errors, setErrors] = useState({});
  const [orderPayment, setOrderPayment] = useState({});
  const [loading, setLoading] = useState(true);
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const upload_location_image = async (imageFile) => {
    try {
      const data = new FormData();
      data.append("location_image", imageFile);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/images/order/location/`,
        data
      );

      // console.log("Image Upload Response:", response.data);
      return response.data.data.file;

      // Lakukan sesuatu dengan respons, jika diperlukan
    } catch (error) {
      console.error("Image Upload Error:", error);
      throw error; // Anda dapat menangani error lebih lanjut atau melemparkannya ke tingkat yang lebih tinggi
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Menggunakan Promise.all untuk menunggu kedua promise selesai
        const [paymentResponse] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_API_URL}/commerce/order/${order_id}/payment/${payment_id}`
          ),
          // Add other asynchronous operations if needed
        ]);

        setOrderPayment(paymentResponse.data.data);
        setFormData({
          ...formData,
          amount: paymentResponse.data.data.amount,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [order_id, payment_id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Check if the selected file is an image
    const isImage =
      file && file.type.startsWith("image/") && file.type !== "image/svg+xml";

    setFormData({
      ...formData,
      payment_method: formData.payment_method,
      receipt: isImage ? file : null,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    // Example validation: Check if payment method is empty
    if (!formData.payment_method.trim() || formData.payment_method === "") {
      newErrors.payment_method = "Metode Pembayaran harus dipilih";
    }

    // Example validation: Check if amount is a positive number
    const amount = parseFloat(formData.amount);
    if (isNaN(amount) || amount <= 0) {
      newErrors.amount = "Nominal Pembayaran harus angka positif";
    }

    // Example validation: Check if receipt is an image
    if (formData.payment_method !== "online" && !formData.receipt) {
      newErrors.receipt = "Bukti Pembayaran wajib diisi.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handle_online_payment = async () => {
    try {
      const dpay_token = await request_dpay_token();

      const dpay = window.Durianpay.init({
        locale: "id",
        environment: "production", // Value should be 'production' for both sandbox and live mode
        access_key: dpay_token.access_token,
        site_name: process.env.REACT_APP_API_URL,
        dark_mode: false,
        redirect_url: `${process.env.REACT_APP_URL}/payment/${order_id}/status/update/${dpay_token.payment_id}`,
        order_info: {
          id: dpay_token.id,
        },
        container_elem: "pay-btn-container",

        onSuccess: function () {
          console.log("Payment success");
        },

        onFailure: function () {
          console.log("Transaction was not completed, transaction failed!");
        },
      });
      dpay.onSuccess = function () {
        // Kode khusus onSuccess yang baru
        console.log("Custom onSuccess override");
        return;
      };
      dpay.checkout();
    } catch (error) {
      console.log("handle_online_payment: ", error);
    }
  };

  const request_dpay_token = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/commerce/payment/dpay/cbd/${order_id}?payment_id=${payment_id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      return response.data.data;
    } catch (error) {
      console.log("request_dpay_token: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      try {
        if (formData.payment_method !== "online") {
          setLoading(true); // Set loading menjadi true saat proses pengiriman data

          const image_location = await upload_location_image(formData.receipt);

          const payment_data = {
            payment_method: formData.payment_method,
            receipt: image_location,
          };

          await create_payment(payment_data);

          addToast("Pembayaran Berhasil Dilakukan", {
            appearance: "success",
            autoDismiss: true,
          });

          navigate(`/history/detail/${order_id}`);
        } else {
          await handle_online_payment();
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false); // Set loading menjadi false setelah proses selesai, baik berhasil atau gagal
      }
    }
  };

  const create_payment = async (payment_data) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/commerce/order/${order_id}/payment/${payment_id}`,
        payment_data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log("Payment Creation Response:", response.data);
      // Lakukan sesuatu dengan respons, jika diperlukan
    } catch (error) {
      console.error("Order Creation Error:", error);
      throw error; // Anda dapat menangani error lebih lanjut atau melemparkannya ke tingkat yang lebih tinggi
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group mb-3">
            <label htmlFor="payment_method">Metode Pembayaran</label>
            <select
              type="text"
              className={`form-control ${
                errors.payment_method ? "is-invalid" : ""
              }`}
              id="payment_method"
              name="payment_method"
              value={formData.payment_method}
              onChange={handleChange}
            >
              <option>Pilih Metode Pembayaran</option>
              <option value={"cash"}>Tunai</option>
              <option value={"transfer"}>Transfer Bank</option>
              <option value={"online"}>Online Virtual Account</option>
            </select>

            {formData.payment_method === "transfer" && (
              <div className="form-info">
                Pembayaran dengan metode transfer dilakukan ke:{" "}
                <b>PT ARA ANAK BANGSA</b> Bank BCA <b>5265-86-1986</b> kemudian
                upload bukti transfer ke form di bawah.
              </div>
            )}

            {errors.payment_method && (
              <div className="invalid-feedback">{errors.payment_method}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label htmlFor="amount">Nominal Pembayaran</label>
            <NumericFormat
              value={formData.amount}
              displayType={"input"}
              thousandSeparator={true}
              prefix={"Rp "}
              readOnly
              onValueChange={(values) => {
                const { value } = values;
                setFormData({
                  ...formData,
                  amount: value,
                });
              }}
              className={`form-control ${errors.amount ? "is-invalid" : ""}`}
            />
            {errors.amount && (
              <div className="invalid-feedback">{errors.amount}</div>
            )}
          </div>

          {formData.payment_method !== "online" && (
            <div className="form-group">
              <label htmlFor="receipt">Bukti Pembayaran</label>
              <input
                type="file"
                accept="image/*"
                className={`form-control ${errors.receipt ? "is-invalid" : ""}`}
                id="receipt"
                name="receipt"
                onChange={handleFileChange}
              />
              {errors.receipt && (
                <div className="invalid-feedback">{errors.receipt}</div>
              )}
            </div>
          )}

          <button
            type="submit"
            className="btn btn-action-order btn-danger mt-4"
            disabled={loading} // Tambahkan properti disabled pada tombol jika loading
          >
            {loading ? "Sedang Memproses..." : "Proses Pembayaran"}
          </button>
        </div>
      </div>
    </form>
  );
};

export default PaymentCreditForm;
