// src/redux/actions/homeMenuActions.js

// Action Types
export const UPDATE_HOME_MENU = "UPDATE_HOME_MENU";

// Action Creators

export const updateHomeMenu = (home_menu) => ({
  type: UPDATE_HOME_MENU,
  payload: home_menu,
});

// Thunk to fetch parent categories
export const fetchHomeMenu = () => async (dispatch) => {
  try {
    const storedHomeMenu = localStorage.getItem("home_menu");
    if (storedHomeMenu) {
      const parsedCart = JSON.parse(storedHomeMenu);
      dispatch({
        type: UPDATE_HOME_MENU,
        payload: parsedCart,
      });
    }
  } catch (error) {
    console.error("Error loading cart from local storage:", error);
  }
};
