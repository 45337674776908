import React from "react";
import {
  formatPrice,
  formattedDate,
  getFormatedOrderID,
} from "../../helpers/AppHelpers";

const OrderCard = ({ order, onClick }) => {
  return (
    <>
      <div className="card card-history" onClick={onClick}>
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <h5 className="card-title">#{order.tenant_name}</h5>
            <div className={`badge ${order.status.toLowerCase()} sm`}>
              {order.status}
            </div>
          </div>

          <div
            className="d-flex justify-content-between"
            style={{ borderBottom: "1px dashed #eee", paddingBottom: "10px" }}
          >
            <div>ID: {order.order_id}</div>
          </div>
          <div className="d-flex justify-content-between sub-header mt-2">
            <div>
              <i className="bx bx-user mr-1"></i>
              {order.user_info.name} | {formatPrice(order.total)}
            </div>
            <div>{formattedDate(order.createdAt)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderCard;
