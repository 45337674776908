// src/redux/store.js
import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import categoryReducer from "./reducers/categoryReducer";
import cartReducer from "./reducers/cartReducer";
import homeMenuReducer from "./reducers/homeMenuReducer";
import searchReducer from "./reducers/searchReducer";

const rootReducer = combineReducers({
  category: categoryReducer,
  cart: cartReducer,
  home_menu: homeMenuReducer,
  search: searchReducer,
  // Add other reducers as needed
});

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
