import { UPDATE_CART } from "../actions/cartActions";

// src/redux/reducers/cartReducer.js
const initialState = {
  carts: [],
};

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CART:
      localStorage.setItem("carts", JSON.stringify(action.payload));
      return {
        ...state,
        carts: action.payload,
      };

    default:
      return state;
  }
};

export default cartReducer;
