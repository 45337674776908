import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import {
  calculateMenuDiscount,
  convertWeightToTon,
  formatPrice,
  isActiveMenu,
} from "../../helpers/AppHelpers";

const OrderSummary = () => {
  const [items, setItems] = useState([]);
  const [total, setTotal] = useState({ qty: 0, weight: 0, cbm: 0 });
  const carts = useSelector((state) => state.cart.carts);
  const [cachedMenuData, setCachedMenuData] = useState({});

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        if (carts.length === 0) {
          // No items in the cart, reset state
          setItems([]);
          setTotal({ qty: 0, weight: 0, cbm: 0, total: 0 });
          return;
        }

        const newItems = [];
        let newTotal = { qty: 0, weight: 0, cbm: 0, total: 0 };

        // Fetch data for new items in the cart
        for (const item of carts) {
          if (!cachedMenuData[item.id]) {
            const response = await axios.get(
              `${process.env.REACT_APP_API_URL}/commerce/menu/tenant/${process.env.REACT_APP_TENANT_ID}/menus/${item.id}`
            );
            const menu = response.data.data;
            cachedMenuData[item.id] = menu;
          }

          const menuData = cachedMenuData[item.id];
          if (!isActiveMenu(menuData)) {
            continue;
          }

          const cartItem = {
            name: menuData.name,
            price: menuData.price * item.qty,
            discount: calculateMenuDiscount(menuData, item.qty),
            qty: item.qty,
            unit: menuData.unit,
          };

          newItems.push(cartItem);
          newTotal.qty += item.qty;
          newTotal.total += cartItem.price - cartItem.discount;
          newTotal.weight +=
            convertWeightToTon(menuData.weight.value, menuData.weight.unit) *
            item.qty;
          newTotal.cbm += menuData.volume * item.qty;
        }

        setItems(newItems);
        setTotal(newTotal);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenuData();
  }, [carts, cachedMenuData]);

  return (
    <table className="table table-sm table-order-amount border-0 mt-4">
      <tbody>
        {items.length > 0 &&
          items.map((item, index) => (
            <tr key={index}>
              <td colSpan="2">
                <div className="item">
                  <div className="title">{item.name}</div>
                  <div className="info">
                    <div className="qty">qty: {item.qty}x</div>

                    <div className="price">
                      {item.discount === 0 ? (
                        formatPrice(item.price)
                      ) : (
                        <>
                          <span className="discount">
                            {formatPrice(item.price)}
                          </span>
                          {formatPrice(item.price - item.discount)}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        {total.total !== undefined && (
          <tr className="total">
            <td className="align-middle">
              <div className="title">Total:</div>
            </td>
            <td>
              <div className="total-container">
                <div className="price">{formatPrice(total.total)}</div>
                <div className="cbm">
                  <span>{total.qty}dus</span> -{" "}
                  <span>{total.weight.toFixed(2)} Ton</span> -
                  <span> CBM: {total.cbm.toFixed(2)}</span>
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default OrderSummary;
