// components/OrderSummaryTable.js
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  calculateMenuDiscount,
  formatPrice,
  isActiveMenu,
} from "../../helpers/AppHelpers";
import axios from "axios";

const OrderSummaryTable = ({ orderSummary, setOrderSummary, setTempCarts }) => {
  const carts = useSelector((state) => state.cart.carts);

  const [tenant, setTenant] = useState({});

  useEffect(() => {
    const fetchTenantData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/tenant/user/${process.env.REACT_APP_TENANT_ID}`
        );
        setTenant(response.data.data);
      } catch (error) {
        console.error("Error fetching tenant data:", error);
      }
    };

    fetchTenantData();
  }, []);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        if (carts.length === 0) {
          return;
        }

        // Create an array of promises for fetching menu data
        const menuPromises = carts.map((item) =>
          axios.get(
            `${process.env.REACT_APP_API_URL}/commerce/menu/tenant/${process.env.REACT_APP_TENANT_ID}/menus/${item.id}`
          )
        );

        // Wait for all promises to resolve
        const menuResponses = await Promise.all(menuPromises);

        // Calculate order summary for each menu
        let newSummary = {
          subtotal: 0,
          discount: 0,
          total: 0,
          ppn: 0,
          amountPaid: 0,
        };

        const temp_carts = carts;
        menuResponses.forEach((response, index) => {
          const menu = response.data.data;

          if (!isActiveMenu(menu)) {
            return;
          }

          temp_carts[index].isActive = isActiveMenu(menu);

          const menu_price = carts[index].qty * menu.price;
          const discount = calculateMenuDiscount(menu, carts[index].qty);
          newSummary = {
            ...newSummary,
            subtotal: newSummary.subtotal + menu_price,
            discount: newSummary.discount + discount,
            total: newSummary.total + (menu_price - discount),
            amountPaid: newSummary.amountPaid + (menu_price - discount),
          };
        });

        setTempCarts(temp_carts);

        // Calculate ppn based on the total and tenant's tax charge
        if (Object.keys(tenant).length > 0) {
          newSummary = {
            ...newSummary,
            ppn: newSummary.total * (tenant.taxCharge / 100),
            amountPaid:
              newSummary.total * (tenant.taxCharge / 100) +
              newSummary.amountPaid,
          };
        }

        setOrderSummary(newSummary);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    fetchMenuData();
  }, [carts, setOrderSummary, tenant]);

  return (
    <table className="table table-sm table-order-amount border-0 mt-4">
      <tbody>
        <tr>
          <th width="30%">Subtotal:</th>
          <td className="text-right">{formatPrice(orderSummary.subtotal)}</td>
        </tr>
        <tr>
          <th width="30%">Diskon:</th>
          <td className="text-right">- {formatPrice(orderSummary.discount)}</td>
        </tr>
        <tr>
          <th width="40%">Biaya Pengiriman:</th>
          <td className="text-right text-muted">{formatPrice(0)}</td>
        </tr>
        <tr>
          <th width="30%">Total:</th>
          <td className="text-right">{formatPrice(orderSummary.total)}</td>
        </tr>

        {/* <tr>
          <th width="30%">Ppn({tenant.taxCharge}%):</th>
          <td className="text-right">{formatPrice(orderSummary.ppn)}</td>
        </tr> */}
        <tr>
          <td colSpan={2}>
            <div>Harga yang dibayar</div>
            <div className="text-right">
              <b>{formatPrice(orderSummary.amountPaid)}</b>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default OrderSummaryTable;
