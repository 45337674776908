// Home.js
import React from "react";
import PageHeader from "../../../components/PageHeader";
import VerificationForm from "./VerificationForm";

const OtpVerification = () => {
  return (
    <>
      <PageHeader title={"Verifikasi OTP"} />
      <VerificationForm />
    </>
  );
};

export default OtpVerification;
