// CountdownTimer.jsx
import React, { useState, useEffect } from "react";
import { parse } from "date-fns";

const CountdownTimer = ({ formattedOtpExpiredDate, onCountdownFinish }) => {
  const [otpCountdownText, setOtpCountdownText] = useState("00:00");

  useEffect(() => {
    const expirationTime = parse(
      formattedOtpExpiredDate,
      "dd/MM/yyyy, HH:mm:ss XXX",
      new Date()
    );
    const currentTime = new Date();

    // console.log(currentTime);
    if (currentTime > expirationTime) {
      onCountdownFinish();
      return;
    }

    const countdownInterval = setInterval(() => {
      const remainingTime = Math.max(
        0,
        Math.floor((expirationTime - new Date()) / 1000)
      );

      const minutes = Math.floor(remainingTime / 60);
      const seconds = remainingTime % 60;
      const formattedMinutes = String(minutes).padStart(2, "0");
      const formattedSeconds = String(seconds).padStart(2, "0");
      setOtpCountdownText(`${formattedMinutes}:${formattedSeconds}`);

      // Set the countdownFinished state when countdown reaches 0
      if (remainingTime === 0) {
        clearInterval(countdownInterval);
        if (onCountdownFinish) {
          onCountdownFinish();
        }
      }
    }, 1000);

    // Cleanup interval when component unmounts or countdown reaches 0
    return () => clearInterval(countdownInterval);
  }, [formattedOtpExpiredDate, onCountdownFinish]);

  return (
    <p className="text-info-countdown mb-3">
      Kode verifikasi akan kedaluwarsa dalam{" "}
      <span className="timer">{otpCountdownText}</span>
    </p>
  );
};

export default CountdownTimer;
