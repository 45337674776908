import React from "react";
import PaymentStatusBadge from "../../../components/PaymentStatusBadge";

const OrderStatus = ({ order }) => {
  let status = "";
  switch (order.status) {
    case "pending":
      status = "Menunggu Pembayaran";
      break;
    case "processing":
      status = "Sedang Diproses";
      break;
    case "shipping":
      status = "Dalam Pengiriman";
      break;
    case "delivered":
      status = "Telah Diterima";
      break;
    case "cancelled":
      status = "Dibatalkan";
      break;
    default:
      status = "Status tidak diketahui";
  }
  return (
    <div className="card card-detail mb-4">
      <div className="card-body">
        <h5 className="title">Detail Pesanan</h5>
        <table className="table table-item-list">
          <tbody>
            <tr>
              <td className="name ">Status Pesanan</td>
              <td className="total ">
                <div className={`badge ${order.status.toLowerCase()} sm`}>
                  {status}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OrderStatus;
