// AppHelper.js
export function formatPrice(price) {
  const splited_price = price ? price.toString().split(".") : "0".split(".");
  const formattedPrice = `Rp ${splited_price[0]
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
  return formattedPrice;
}

export const calculateMenuDiscount = (menu, quantity) => {
  if (!menu) {
    return 0;
  }
  let total = menu.price * quantity;
  let discount_total = 0;

  if (menu.discount.length > 0) {
    // Sort discounts in descending order based on quantity
    const sortedDiscounts = menu.discount.sort(
      (a, b) => b.quantity - a.quantity
    );

    for (const discount of sortedDiscounts) {
      if (quantity >= discount.quantity) {
        if (discount.type === "price") {
          // Apply fixed price discount
          discount_total = discount.value;
        } else {
          // Apply percentage discount
          discount_total = total * (discount.value / 100);
        }
        // Break the loop after applying the first eligible discount
        break;
      }
    }
  }

  // Deduct discount from the total
  total = total - discount_total;

  return discount_total;
};

export const formattedDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  const formatted = new Date(dateString).toLocaleDateString("id-ID", options);

  return formatted;
};

export const getFormatedOrderID = (order_id) => {
  const order = order_id.split("-");
  if (order.length > 1) {
    return `${order[2]}-${order[3]}`;
  }
  return "";
};

export const convertWeightToTon = (weight, unit) => {
  const conversionFactors = {
    g: 0.000001,
    Kg: 0.001,
    ml: 0.000001,
    L: 0.001, // Corrected conversion factor for liters
    ton: 1000,
  };
  return weight * conversionFactors[unit];
};

export const isActiveMenu = (menu) => {
  return (
    menu.isActive &&
    (menu.isUnlimited || (!menu.isUnlimited && menu.quantity > 0))
  );
};
