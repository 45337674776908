import React from "react";

const PageHeader = ({ title, onBackButtonClick = null }) => {
  return (
    <div className="page-header">
      {onBackButtonClick != null && (
        <div className="btn btn-back btn-light" onClick={onBackButtonClick}>
          <i className="bx bx-chevron-left"></i>
        </div>
      )}
      <h3 className="page-title">{title}</h3>
    </div>
  );
};

export default PageHeader;
