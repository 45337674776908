import { UPDATE_HOME_MENU } from "../actions/homeMenuActions";

// src/redux/reducers/homeMenuReducer.js
const initialState = {
  home_menu: {
    category_id: "all",
    sub_category_id: "all",
  },
};

const homeMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_HOME_MENU:
      localStorage.setItem("home_menu", JSON.stringify(action.payload));
      return {
        ...state,
        home_menu: action.payload,
      };

    default:
      return state;
  }
};

export default homeMenuReducer;
