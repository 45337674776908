import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategories,
  updateSearchData,
} from "../../redux/actions/searchActions";

const SearchBox = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCategories());
  }, []);

  const search = useSelector((state) => state.search);

  const handleOnChange = (event) => {
    const query = event.target.value;
    let filteredResults = [];

    if (query !== "") {
      // Filter the results based on the query and menus.name
      filteredResults = search.menus.filter((menu) =>
        menu.name.toLowerCase().includes(query.toLowerCase())
      );
    }

    dispatch(
      updateSearchData({
        query: query,
        results: filteredResults,
        menus: search.menus,
      })
    );
  };

  return (
    <div className="search-box mb-3">
      <i className="bx bx-search"></i>
      <input
        type="text"
        className="form-control"
        value={search.query}
        onChange={handleOnChange}
        placeholder="Cari item di sini..."
        disabled={!search.menus.length > 0}
      />
    </div>
  );
};

export default SearchBox;
