import axios from "axios";

// Action Types
export const UPDATE_SEARCH_DATA = "UPDATE_SEARCH_DATA";

// Action Creators

export const updateSearchData = (search_data) => ({
  type: UPDATE_SEARCH_DATA,
  payload: search_data,
});

// Thunk to fetch parent categories
export const fetchCategories = () => async (dispatch, getState) => {
  try {
    const { search } = getState(); // Get the current state

    // Your API call or any other asynchronous operations
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/commerce/menu/tenant/${process.env.REACT_APP_TENANT_ID}`
    );
    const data = response.data.data[0].categories;

    const menus = [];
    for (const category of data) {
      for (const menu of category.subCategories.menu) {
        menus.push(menu);
      }
    }

    // Dispatch the action using the current state
    dispatch(
      updateSearchData({
        query: search.query, // Use the current state's query
        results: search.results, // Use the current state's results
        menus: menus,
      })
    );
  } catch (error) {
    console.error("Error fetching parent categories:", error);
  }
};
