// Home.js
import React, { useEffect, useState } from "react";
import Header from "./header";
import SearchBox from "../../components/form/SearchBox";
import CategorySelector from "../../components/form/CategorySelector";
import PromoCarousel from "../../components/PromoCarousel";
import CategoryTitle from "../../components/item/CategoryTitle";
import { useSelector } from "react-redux";
import CardItemHome from "./CardItemHome";
import { isActiveMenu } from "../../helpers/AppHelpers";
import DisabledProduct from "../../components/DisabledProduct";
import { useNavigate } from "react-router-dom";
import SearchView from "./SearchView";

const Home = () => {
  const navigate = useNavigate();
  const subCategories = useSelector((state) => state.category.subCategories);
  const [showedMenus, setShowedMenus] = useState([]);
  const home_menu = useSelector((state) => state.home_menu.home_menu);
  useEffect(() => {
    // Sort subCategories alphabetically by name
    const sortedSubCategories = subCategories
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));

    if (home_menu.sub_category_id === "all") {
      setShowedMenus(sortedSubCategories);
      return;
    }

    const filteredMenus = sortedSubCategories.filter(
      (item) => item.id === home_menu.sub_category_id && item.menus.length > 0
    );
    setShowedMenus(filteredMenus);
  }, [home_menu, subCategories]);

  const onClick = (menu_id) => {
    navigate(`/menu/${menu_id}`);
  };

  const search = useSelector((state) => state.search);

  return (
    <>
      <Header />
      <div className={`sticky-content ${search.query === "" ? "mb-3" : ""}`}>
        <div className="tf-container">
          {/* Search Box */}
          <SearchBox />
          {/* End Search Box */}

          {search.query === "" && (
            <CategorySelector subCategories={subCategories} />
          )}
        </div>
      </div>

      <div className="tf-container" style={{ marginBottom: "100px" }}>
        {search.query === "" && <PromoCarousel />}

        {search.query === "" ? (
          showedMenus.map((category) => (
            <section className="mb-4" key={category.id}>
              <CategoryTitle categoryTitle={category.name} />
              {category.menus.length > 0 &&
                category.menus
                  .sort((a, b) => (b.isRecommend ? 1 : -1)) // Sort by isRecommend (recommended menus first)
                  .map((menu, index) =>
                    isActiveMenu(menu) ? (
                      <CardItemHome key={index} menu={menu} onClick={onClick} />
                    ) : (
                      <DisabledProduct key={index} menu={menu} />
                    )
                  )}
            </section>
          ))
        ) : (
          <SearchView search={search} />
        )}
      </div>
    </>
  );
};

export default Home;
