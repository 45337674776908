import React from "react";
import { formatPrice } from "../../../helpers/AppHelpers";

const SalesCommission = ({ order, user }) => {
  return (
    <>
      {order.sales_id && user?.isSales ? (
        <div className="card card-detail mb-4">
          <div className="card-body">
            <h5 className="title">Komisi Penjualan</h5>
            <table className="table table-item-list">
              <tbody>
                {order.menu.map((item) => (
                  <tr key={item.id}>
                    <td className="name ">{item.name}</td>
                    <td className="total ">
                      {formatPrice(item.sales_commision)}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className="name lg">Total Komisi</td>
                  <td className="total lg">
                    {formatPrice(order.sales_commission.total)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default SalesCommission;
