import React from "react";

const Header = () => {
  return (
    <div className="inner-headerbar">
      <span className="header-title">Partner:</span>
      <div className="header-location">Oasis Jakarta</div>
    </div>
  );
};

export default Header;
