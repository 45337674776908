// OtpForm.js
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications";

const OtpForm = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false); // Add loading state
  const { addToast } = useToasts();

  const handlePhoneNumberChange = (e) => {
    const inputNumber = e.target.value.replace(/\D/g, "");
    setPhoneNumber(inputNumber);
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    // Set loading to true when starting the OTP request
    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/signin`,
        {
          phone_number: `+62${phoneNumber}`,
        }
      );

      addToast(response.data.message, {
        appearance: "success",
        autoDismiss: true,
      });
      localStorage.setItem("phoneNumber", `+62${phoneNumber}`);
      localStorage.setItem("otpExpiredDate", response.data.expired_date);
      navigate("/login/otp");
    } catch (error) {
      console.error(error);
      addToast("Terjadi kesalahan saat mengirim OTP", {
        appearance: "error",
        autoDismiss: true,
      });
    } finally {
      // Set loading to false regardless of success or failure
      setLoading(false);
    }
  };

  return (
    <div className="tf-container" style={{ marginTop: "60px" }}>
      <form onSubmit={handleOtpSubmit}>
        <div className="form-group mb-3">
          <label htmlFor="">Nomor Whatsapp</label>
          <div className="input-group">
            <div className="input-group-prepend">
              <div className="input-group-text">+62</div>
            </div>
            <input
              type="text"
              className="form-control"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              inputMode="numeric"
            />
          </div>
        </div>
        <p className="text-muted-otp mb-5">
          Masukkan nomor WhatsApp Anda untuk menerima kode OTP dan melanjutkan
          proses login.
        </p>
        <button
          type="submit"
          className="btn btn-action-order btn-danger"
          disabled={loading}
        >
          {loading ? "Mengirim OTP..." : "Kirim OTP"}
        </button>
      </form>
    </div>
  );
};

export default OtpForm;
