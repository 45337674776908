import React, { useState } from "react";
import { formatPrice, formattedDate } from "../../../helpers/AppHelpers";
import PaymentStatusBadge from "../../../components/PaymentStatusBadge";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useNavigate } from "react-router-dom";

const PaymentHistory = ({ orderPayment }) => {
  const [photoIndex, setPhotoIndex] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [collapsedRows, setCollapsedRows] = useState([]);
  const navigate = useNavigate();

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const handleCollapseToggle = (historyId) => {
    setCollapsedRows((prevRows) => {
      return prevRows.includes(historyId)
        ? prevRows.filter((id) => id !== historyId)
        : [...prevRows, historyId];
    });
  };

  const renderImages = () => {
    return orderPayment.history.map((history, index) => ({
      src: `${process.env.REACT_APP_API_URL}/${history.receipt}`,
      alt: `Bukti Pembayaran ${index + 1}`,
    }));
  };

  return (
    <div className="card card-detail">
      <div className="card-body">
        <h5 className="title">Riwayat Pembayaran</h5>

        <table className="table table-item-list payment-history">
          <tbody>
            {orderPayment.history.map((history, index) => (
              <React.Fragment key={history.id}>
                <tr
                  className="cursor-pointer"
                  onClick={() => handleCollapseToggle(history.id)}
                >
                  <td className="name ">ID</td>
                  <td className="total ">{history.id}</td>
                </tr>

                <tr>
                  <td className="name ">Status</td>
                  <td className="total ">
                    <PaymentStatusBadge status={history.status} />
                  </td>
                </tr>

                <tr
                  className={`collapse ${
                    collapsedRows.includes(history.id) ? "show" : ""
                  }`}
                >
                  <td className="name ">Tanggal</td>
                  <td className="total">{formattedDate(history.date)}</td>
                </tr>

                <tr
                  className={`collapse ${
                    collapsedRows.includes(history.id) ? "show" : ""
                  }`}
                >
                  <td className="name ">Jatuh Tempo</td>
                  <td className="total">
                    {history.due_date && formattedDate(history.due_date)}
                  </td>
                </tr>

                <tr
                  className={`collapse ${
                    collapsedRows.includes(history.id) ? "show" : ""
                  }`}
                >
                  <td className="name ">Tipe Pembayaran</td>
                  <td className="total text-uppercase">
                    {history.payment_type}
                  </td>
                </tr>

                <tr
                  className={`collapse ${
                    collapsedRows.includes(history.id) ? "show" : ""
                  }`}
                >
                  <td className="name ">Metode Pembayaran</td>
                  <td className="total text-uppercase">
                    {history.payment_method}
                  </td>
                </tr>

                <tr
                  className={`collapse ${
                    collapsedRows.includes(history.id) ? "show" : ""
                  }`}
                >
                  <td className="name ">Jumlah</td>
                  <td className="total">{formatPrice(history.amount)}</td>
                </tr>

                <tr
                  className={`collapse ${
                    collapsedRows.includes(history.id) ? "show" : ""
                  }`}
                >
                  <td className="name ">Bukti</td>
                  <td className="total ">
                    {history.receipt !== null ? (
                      <button
                        className="btn btn-see-receipt"
                        onClick={() => openLightbox(index)}
                      >
                        Lihat Bukti Pembayaran
                      </button>
                    ) : (
                      "Belum ada"
                    )}
                  </td>
                </tr>

                {history.status === "waiting_payment" && (
                  <tr>
                    <td colSpan={2}>
                      <button
                        className="btn btn-action-payment btn-danger"
                        onClick={() => {
                          navigate(
                            `/payment/${orderPayment.order_id}/${history.id}`
                          );
                        }}
                      >
                        Selesaikan Pembayaran
                      </button>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>

        {isOpen && (
          <Lightbox
            mainSrc={renderImages()[photoIndex].src}
            nextSrc={
              renderImages()[(photoIndex + 1) % renderImages().length].src
            }
            prevSrc={
              renderImages()[
                (photoIndex + renderImages().length - 1) % renderImages().length
              ].src
            }
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + renderImages().length - 1) % renderImages().length
              )
            }
            onMoveNextRequest={() =>
              setPhotoIndex((photoIndex + 1) % renderImages().length)
            }
          />
        )}
      </div>
    </div>
  );
};

export default PaymentHistory;
