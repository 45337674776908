import React from "react";
import PageHeader from "../../components/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import CategoryTitle from "../../components/item/CategoryTitle";
import PaymentForm from "./PaymentForm";

const Payment = () => {
  const { order_id } = useParams();
  const navigate = useNavigate();
  const onBackButtonClick = () => {
    navigate(`/history/detail/${order_id}`);
  };

  return (
    <>
      <PageHeader title={"Pembayaran"} onBackButtonClick={onBackButtonClick} />

      <div className="tf-container" style={{ margin: "80px auto 100px auto" }}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="card">
              <div className="card-body">
                <CategoryTitle categoryTitle={"Data Pembayaran"} />

                <PaymentForm order_id={order_id} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
