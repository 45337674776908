import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import CategoryTitle from "../../components/item/CategoryTitle";
import OrderForm from "./OrderForm";
import OrderSummaryTable from "./OrderSummaryTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchCarts, updateCart } from "../../redux/actions/cartActions";

import axios from "axios";
import FormData from "form-data";
import { useNavigate } from "react-router";
import { isActiveMenu } from "../../helpers/AppHelpers";

const Order = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [tempCarts, setTempCarts] = useState(null);

  useEffect(() => {
    const storeduser = JSON.parse(localStorage.getItem("user"));
    setUser(storeduser);
    setAddresses(storeduser.addresses);
  }, []);

  const [formData, setFormData] = useState({
    is_sales: false,
    sales_id: "",
    customer_name: "",
    customer_phone: "+62",
    user_name: "",
    user_phone: "",
    address_id: "",
    address: "",
    gmaps_link: "",
    location_image: null,
  });

  useEffect(() => {
    // Hanya perbarui user_name dari user state jika user_name belum diisi oleh pengguna
    if (!formData.user_name && user) {
      setFormData((prevData) => ({
        ...prevData,
        user_name: user.name,
      }));
    }

    // Selalu perbarui data selain user_name
    if (user && addresses) {
      setFormData((prevData) => ({
        ...prevData,
        user_phone: user?.phoneNumber,
        address_id: addresses?.length > 0 ? addresses[0]?.id : "",
        address: addresses?.length > 0 ? addresses[0]?.address : "",
        gmaps_link: addresses?.length > 0 ? addresses[0]?.gmaps_link : "",
      }));
    }
  }, [user, addresses]);

  const upload_location_image = async (imageFile) => {
    try {
      const data = new FormData();
      data.append("location_image", imageFile);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/images/order/location/`,
        data
      );

      // console.log("Image Upload Response:", response.data);
      return response.data.data.file;

      // Lakukan sesuatu dengan respons, jika diperlukan
    } catch (error) {
      console.error("Image Upload Error:", error);
      throw error; // Anda dapat menangani error lebih lanjut atau melemparkannya ke tingkat yang lebih tinggi
    }
  };

  const update_user_data = async (user_id) => {
    try {
      const user = await axios.get(
        `${process.env.REACT_APP_API_URL}/user/retrieve/${user_id}`
      );

      setUser(user.data.data);
      localStorage.setItem("user", JSON.stringify(user.data.data));
    } catch (error) {
      console.log("User: ", error);
    }
  };

  const [errors, setErrors] = useState({});

  const handleSubmit = async () => {
    // Validate the form using the validateForm function from OrderForm
    if (OrderForm.validateForm(formData, setErrors)) {
      // Handle form submission, e.g., send data to the server
      try {
        setLoading(true); // Set loading menjadi true saat proses pengiriman data

        const image_location = await upload_location_image(
          formData.location_image
        );

        const is_sales = formData.is_sales;
        const orderData = {
          tenant_id: process.env.REACT_APP_TENANT_ID,
          is_sales: is_sales,
          sales_id: is_sales ? user.user_id : "",
          user_info: {
            name: is_sales ? formData.customer_name : formData.user_name,
            phone_number: is_sales
              ? formData.customer_phone
              : formData.user_phone,
            address: {
              id: formData.address_id,
              address: formData.address,
              gmaps_link: formData.gmaps_link,
              image: image_location,
            },
          },
          menu: tempCarts
            .filter((item) => item.isActive)
            .map((item) => {
              return {
                id: item.id,
                quantity: item.qty,
              };
            }),
        };

        // Create the order
        await create_order(orderData);
        setLoading(false);

        localStorage.setItem("cart", JSON.stringify([]));
        dispatch(updateCart([]));

        update_user_data(user.user_id);
        navigate("/order/success");
      } catch (error) {
        console.error("Error submitting form:", error);
      } finally {
        setLoading(false); // Set loading menjadi false setelah proses selesai, baik berhasil atau gagal
      }
    }
  };

  const create_order = async (orderData) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/commerce/order`,
        orderData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      // Lakukan sesuatu dengan respons, jika diperlukan
    } catch (error) {
      console.error("Order Creation Error:", error);
      throw error; // Anda dapat menangani error lebih lanjut atau melemparkannya ke tingkat yang lebih tinggi
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCarts());
  }, [dispatch]);
  const carts = useSelector((state) => state.cart.carts);
  const [orderSummary, setOrderSummary] = useState({
    subtotal: 0,
    discount: 0,
    total: 0,
    ppn: 0,
    amountPaid: 0,
  });

  const onBackButtonClick = () => {
    alert("/cart");
  };

  return (
    <>
      <PageHeader
        title={"Buat Pesanan"}
        onBackButtonClick={onBackButtonClick}
      />

      <div className="tf-container" style={{ margin: "80px auto 100px auto" }}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="card">
              <div className="card-body">
                <CategoryTitle categoryTitle={"Data Pembeli"} />
                {/* Pass handleChange and errors to OrderForm */}
                <OrderForm
                  formData={formData}
                  errors={errors}
                  setErrors={setErrors}
                  setFormData={setFormData}
                  user={user}
                  setUser={setUser}
                  addresses={addresses}
                  setAddresses={setAddresses}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card mb-3">
              <div className="card-body">
                <CategoryTitle categoryTitle={"Detail Pesanan"} />
                <OrderSummaryTable
                  orderSummary={orderSummary}
                  setOrderSummary={setOrderSummary}
                  setTempCarts={setTempCarts}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Move the submit button outside OrderForm */}
        <button
          type="submit"
          className="btn btn-action-order btn-danger"
          onClick={handleSubmit}
          disabled={loading} // Tambahkan properti disabled pada tombol jika loading
        >
          {loading ? "Sedang Memproses..." : "Buat Pesanan"}
        </button>
      </div>
    </>
  );
};

export default Order;
