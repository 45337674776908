import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { RingLoader } from "react-spinners";

const AddressInputs = ({
  index,
  address,
  onAddressChange,
  onRemoveAddress,
  errors,
}) => {
  const { id, address: addressValue, gmaps_link } = address;
  const [isCollapsed, setIsCollapsed] = useState(!id);

  useEffect(() => {
    // Set isCollapsed to true for new addresses when component mounts
    if (!id) {
      setIsCollapsed(true);
    }
  }, [id]);

  const handleCollapseToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <div className="address-item mb-3">
      <div className="d-flex justify-content-between align-items-center">
        <div
          className="address-title"
          onClick={handleCollapseToggle}
          data-toggle="collapse"
          data-target={`#addressInputs-${id}`}
          aria-expanded={isCollapsed ? "false" : "true"}
        >
          {`Alamat ke-${index + 1}`}
        </div>
        <button
          type="button"
          className={`btn btn-remove ${!isCollapsed ? "d-none" : ""}`}
          onClick={onRemoveAddress}
        >
          <i className="bx bx-trash"></i>
        </button>
      </div>

      <div
        className={`collapse ${isCollapsed ? "show" : ""}`}
        id={`addressInputs-${id}`}
      >
        <div className="form-group my-3">
          <label>Alamat</label>
          <textarea
            className={`form-control ${errors.address ? "is-invalid" : ""}`}
            id={`address-${id}`}
            name={`address-${id}`}
            value={addressValue}
            onChange={(e) => onAddressChange("address", e.target.value)}
          />
          {errors.address && (
            <div className="invalid-feedback">{errors.address}</div>
          )}
        </div>

        <div className="form-group">
          <label>Link Gmaps</label>
          <input
            type="text"
            className={`form-control ${errors.gmaps_link ? "is-invalid" : ""}`}
            id={`gmaps_link-${id}`}
            name={`gmaps_link-${id}`}
            value={gmaps_link}
            onChange={(e) => onAddressChange("gmaps_link", e.target.value)}
          />
          {errors.gmaps_link && (
            <div className="invalid-feedback">{errors.gmaps_link}</div>
          )}
        </div>
      </div>
    </div>
  );
};

const EditProfile = () => {
  const [user, setUser] = useState(null);
  const [formData, setFormData] = useState({
    phoneNumber: "",
    name: "",
    addresses: [],
  });
  const [loading, setLoading] = useState(true);
  const [isFormSubmitting, setFormSubmitting] = useState(false);
  const [errors, setErrors] = useState({}); // New state for validation errors

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUser = JSON.parse(localStorage.getItem("user"));

        if (storedUser) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/user/retrieve/${storedUser.user_id}`
          );

          const data = response.data.data;
          setUser(data);
          setFormData({
            phoneNumber: data.phoneNumber,
            name: data.name,
            addresses: data.addresses,
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  const onBackButtonClick = () => {
    navigate(`/profile`);
  };

  const handleAddressChange = (index, field, value) => {
    // Clear validation errors when the user makes changes
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" }));

    const updatedAddresses = [...formData.addresses];
    updatedAddresses[index][field] = value;
    setFormData((prevData) => ({ ...prevData, addresses: updatedAddresses }));
  };

  const addAddress = () => {
    setFormData((prevData) => ({
      ...prevData,
      addresses: [
        ...prevData.addresses,
        { id: "", address: "", gmaps_link: "", image: "" },
      ],
    }));
  };

  const removeAddress = (index) => {
    const updatedAddresses = [...formData.addresses];
    updatedAddresses.splice(index, 1);
    setFormData((prevData) => ({ ...prevData, addresses: updatedAddresses }));
  };

  const validateForm = () => {
    const errors = {};

    // Validate the 'name' field
    if (!formData.name.trim()) {
      errors.name = "Nama wajib diisi";
    }

    // Validate each address
    formData.addresses.forEach((address, index) => {
      if (!address.address.trim()) {
        errors[`address-${index}`] = "Alamat wajib diisi";
      }
      if (!address.gmaps_link.trim()) {
        errors[`gmaps_link-${index}`] = "Link Gmaps wajib diisi";
      }
    });

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate the form
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      // If there are validation errors, update the state and display errors
      setErrors(validationErrors);
      return;
    }

    try {
      setFormSubmitting(true);
      const userId = user?.user_id;

      // Prepare the request body
      const requestBody = {
        name: formData.name,
        addresses: formData.addresses,
      };

      // Make the PUT request
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/user/${userId}`,
        requestBody
      );

      localStorage.setItem("user", JSON.stringify(response.data.data));
      setFormSubmitting(false);
      navigate(`/profile`);
    } catch (error) {
      console.error("Error updating user data:", error);
      setFormSubmitting(false);
      alert(error);
    }
  };

  return (
    <>
      <PageHeader title={"Edit Profil"} onBackButtonClick={onBackButtonClick} />
      <div className="tf-container" style={{ margin: "80px auto 100px auto" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "60vh",
            }}
          >
            <RingLoader size={50} color={"#d83f31"} loading={loading} />
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label htmlFor="phoneNumber">Nomor Telepon</label>
                  <input
                    type="text"
                    name="phoneNumber"
                    id="phoneNumber"
                    className="form-control"
                    value={formData?.phoneNumber}
                    readOnly
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="name">Nama</label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className={`form-control ${
                      errors.name ? "is-invalid" : ""
                    }`}
                    value={formData?.name}
                    onChange={(e) =>
                      setFormData((prevData) => ({
                        ...prevData,
                        name: e.target.value,
                      }))
                    }
                  />
                  {errors.name && (
                    <div className="invalid-feedback">{errors.name}</div>
                  )}
                </div>

                <div className="addressess mb-3">
                  <div className="addressess-title">Alamat Tersimpan</div>
                  {formData.addresses.map((address, index) => (
                    <AddressInputs
                      key={address.id}
                      index={index}
                      address={address}
                      onAddressChange={(field, value) =>
                        handleAddressChange(index, field, value)
                      }
                      onRemoveAddress={() => removeAddress(index)}
                      errors={{
                        address: errors[`address-${index}`],
                        gmaps_link: errors[`gmaps_link-${index}`],
                      }}
                    />
                  ))}
                  <button
                    type="button"
                    className="btn btn-add-address"
                    onClick={addAddress}
                  >
                    Tambah Alamat
                  </button>
                </div>
                <button
                  type="submit"
                  className="btn btn-action-order btn-danger mt-4"
                  disabled={loading} // Tambahkan properti disabled pada tombol jika loading
                >
                  {loading ? "Sedang Menyimpan..." : "Simpan Perubahan"}
                </button>
              </div>
            </div>
          </form>
        )}
      </div>
    </>
  );
};

export default EditProfile;
