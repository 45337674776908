import React, { useEffect } from "react";
import { formatPrice, isActiveMenu } from "../../helpers/AppHelpers";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { fetchCarts, updateCart } from "../../redux/actions/cartActions";

const CardItemHome = ({ menu, onClick }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const carts = useSelector((state) => state.cart.carts);

  useEffect(() => {
    dispatch(fetchCarts());
  }, [dispatch]);

  const existingCart = (menu) => {
    return carts.find((item) => item.id === menu.id);
  };

  const addToCart = (menu) => {
    let updatedCarts;
    if (existingCart(menu)) {
      // If the item is already in the cart, update its quantity
      updatedCarts = carts.map((item) =>
        item.id === menu.id ? { ...item, qty: item.qty + 1 } : item
      );
    } else {
      // If the item is not in the cart, add it with quantity 1
      updatedCarts = [...carts, { id: menu.id, qty: 1 }];
    }

    // Dispatch an action to update the cart state
    dispatch(updateCart(updatedCarts));

    addToast(`Berhasil menambahkan ${menu.name} ke dalam keranjang`, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  return (
    <div className="product-item">
      <div className="product-image ">
        <img src={menu.menuImage} alt="" />
      </div>
      <div className="product-info">
        <h4 className="title" onClick={() => onClick(menu.id)}>
          {menu.name}
        </h4>
        <div className="price">
          {formatPrice(menu.price)}
          {/* {discountPrice !== 0 && (
            <span className="discount">{formatPrice(discountPrice)}</span>
          )} */}
        </div>
        <div className="bottom-container align-items-bottom">
          <button
            type="button"
            className={`btn btn-sm btn-${
              existingCart(menu) ? "success" : "danger"
            } btn-cart`}
            onClick={() => addToCart(menu)}
          >
            <i className="bx bx-cart"></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CardItemHome;
