import React from "react";
import { formatPrice } from "../helpers/AppHelpers";

const DisabledProduct = ({ menu }) => {
  return (
    <div className="product-disabled">
      <div className="product-image ">
        <img src={menu.menuImage} alt="" />
      </div>
      <div className="product-info">
        <h4 className="title">{menu.name}</h4>
        <div className="price">{formatPrice(menu.price)}</div>
        <div className="message">Produk Habis / Tidak Tersedia</div>
      </div>
    </div>
  );
};

export default DisabledProduct;
