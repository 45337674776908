// pages/cart/Cart.js
import React, { useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { fetchCarts } from "../../redux/actions/cartActions";
import CardItemCart from "./CardItemCart";
import CategoryTitle from "../../components/item/CategoryTitle";
import OrderSummary from "./OrderSummary";
import { Link, useNavigate } from "react-router-dom";
import { isActiveMenu } from "../../helpers/AppHelpers";
import DisabledProduct from "../../components/DisabledProduct";

const Cart = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCarts());
  }, [dispatch]);
  const carts = useSelector((state) => state.cart.carts);

  const onBackButtonClick = () => {
    navigate("/");
  };

  const onClick = (menu_id) => {
    navigate(`/menu/${menu_id}`);
  };

  return (
    <>
      <PageHeader title={"Keranjang"} onBackButtonClick={onBackButtonClick} />

      <div className="tf-container" style={{ margin: "80px auto 100px auto" }}>
        {carts.length > 0 ? (
          <>
            <div className="row">
              <div className="col-md-12">
                {carts.map((menu, index) => (
                  <CardItemCart
                    key={menu.id}
                    id={menu.id}
                    qty={menu.qty}
                    onClick={onClick}
                  />
                ))}
              </div>

              <div className="col-md-12">
                <div className="card mb-3">
                  <div className="card-body">
                    <CategoryTitle categoryTitle={"Ringkasan Pesanan"} />
                    <OrderSummary />
                  </div>
                </div>
              </div>
            </div>

            <Link
              to="/order/create"
              className="btn btn-action-order btn-danger"
            >
              Lanjut Memesan
            </Link>
          </>
        ) : (
          <div className="alert-container default">
            <i className="bx bx-cart-alt"></i>
            <h4 className="title">Keranjang Belanja Kosong</h4>
            <p className="desc">
              Silakan pilih item agar bisa ditampilkan di keranjang.
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default Cart;
