import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { fetchCarts } from "../redux/actions/cartActions";

const BottomMenu = () => {
  const carts = useSelector((state) => state.cart.carts);
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    let count = 0;
    carts.map((cart) => {
      count += cart.qty;
    });
    setCounter(count);
  }, [carts]);

  useEffect(() => {
    dispatch(fetchCarts());
  }, [dispatch]);

  const location = useLocation();

  const isActive = (path) => {
    const curr_path = location.pathname
      .split("/")
      .filter((part) => part !== "");
    // Memeriksa apakah path lokasi cocok dengan path yang diberikan
    // console.log("curr_path: ", curr_path[0]);
    return curr_path[0] === path ? "active" : "";
  };

  return (
    <nav className="navbar navbar-bottom navbar-expand fixed-bottom">
      <ul className="navbar-nav mx-auto">
        <li className={`nav-item ${isActive("")}`}>
          <Link to="/" className="nav-link">
            <i className="bx bx-home-circle"></i>
            Beranda
          </Link>
        </li>
        <li className={`nav-item ${isActive("cart")} ${isActive("order")}`}>
          <Link to="/cart" className="nav-link">
            <i className="bx bx-cart"></i>
            Keranjang
            {counter > 0 && (
              <div className="counter-badge">
                <span>{counter}</span>
              </div>
            )}
          </Link>
        </li>
        <li className={`nav-item ${isActive("history")} `}>
          <Link to="/history" className="nav-link">
            <i className="bx bx-receipt"></i>
            Riwayat
          </Link>
        </li>
        <li className={`nav-item ${isActive("profile")}`}>
          <Link to="/profile" className="nav-link">
            <i className="bx bx-user"></i>
            Profil
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default BottomMenu;
