import React from "react";

const CategoryTitle = ({ categoryTitle }) => {
  return (
    <div className="title-container">
      <h4 className="title">{categoryTitle}</h4>
      <div className="title-sep-container">
        <div className="title-sep"></div>
      </div>
    </div>
  );
};

export default CategoryTitle;
