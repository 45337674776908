import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const { order_id, payment_id } = useParams();
  const [countdown, setCountdown] = useState(3);

  const update_dpay_payment_status = async (data) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/commerce/payment/dpay/webhook/status`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response);

      // Mulai countdown setelah update_dpay_payment_status selesai
      startCountdown();
    } catch (error) {
      throw error; // Anda dapat menangani error lebih lanjut atau melemparkannya ke tingkat yang lebih tinggi
    }
  };

  const startCountdown = () => {
    const countdownInterval = setInterval(() => {
      setCountdown((prevCount) => prevCount - 1);
    }, 1000);

    // Cleanup the interval when component unmounts or countdown reaches 0
    return () => clearInterval(countdownInterval);
  };

  useEffect(() => {
    // Panggil update_dpay_payment_status saat komponen pertama kali di-mount
    update_dpay_payment_status({ payment_id });
  }, [payment_id]);

  useEffect(() => {
    // Redirect saat countdown mencapai 0
    if (countdown === 0) {
      navigate(`/history/detail/${order_id}#pills-riwayat-pembayaran`);
    }
  }, [countdown, navigate]);

  return (
    <>
      <div className="tf-container">
        <div className="alert-container success">
          <i className="bx bx-check-circle"></i>
          <h4 className="title">Pembayaran Berhasil</h4>
          <p className="desc">
            Sistem kami sedang memverifikasi pembayaran anda.
          </p>

          <div className="countdown">
            <span id="countdown">{countdown}</span> detik
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
