// src/redux/actions/cartActions.js

// Action Types
export const UPDATE_CART = "UPDATE_CART";

// Action Creators

export const updateCart = (carts) => ({
  type: UPDATE_CART,
  payload: carts,
});

// Thunk to fetch parent categories
export const fetchCarts = () => async (dispatch) => {
  try {
    const storedCart = localStorage.getItem("carts");
    if (storedCart) {
      const parsedCart = JSON.parse(storedCart);
      dispatch({
        type: UPDATE_CART,
        payload: parsedCart,
      });
    }
  } catch (error) {
    console.error("Error loading cart from local storage:", error);
  }
};
