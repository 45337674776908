import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PageHeader from "../../components/PageHeader";
import axios from "axios";
import { formatPrice } from "../../helpers/AppHelpers";
import DetailProductCart from "./DetailProductCart";

const DetailMenu = () => {
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("pills-deskripsi");
  const [menu, setMenu] = useState(null);
  const { menu_id } = useParams();

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/commerce/menu/tenant/${process.env.REACT_APP_TENANT_ID}/menus/${menu_id}`
        );

        setMenu(response.data.data);
      } catch (error) {
        console.error("Error fetching menu data:", error);
      }
    };

    if (menu_id) {
      fetchMenuData();
    }
  }, [menu_id]);

  const onBackButtonClick = () => {
    // Menggunakan window.history untuk navigasi mundur
    window.history.length > 1 ? window.history.back() : navigate("/");
  };

  return (
    <>
      <PageHeader title={"Detail Item"} onBackButtonClick={onBackButtonClick} />
      <div className="tf-container" style={{ margin: "80px auto 100px auto" }}>
        <div className="card detail-product mb-3">
          <img
            className="card-img-top"
            src={menu?.menuImage}
            alt="Card image cap"
          />
          <div className="card-body">
            <div className="merk">{menu?.merk}</div>
            <h5 className="card-title">{menu?.name}</h5>
            <div className="price">{formatPrice(menu?.price)}</div>

            <DetailProductCart menu={menu} />
          </div>

          <div className="card-footer">
            <ul
              className="nav nav-pills detail-nav"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "pills-deskripsi" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  href="#pills-detail-order"
                  role="tab"
                  onClick={() => handleTabClick("pills-deskripsi")}
                >
                  Deskripsi
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "pills-spesifikasi" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  href="#pills-spesifikasi"
                  role="tab"
                  onClick={() => handleTabClick("pills-spesifikasi")}
                >
                  Spesifikasi
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "pills-diskon" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  href="#pills-diskon"
                  role="tab"
                  onClick={() => handleTabClick("pills-diskon")}
                >
                  Diskon
                </a>
              </li>

              <li className="nav-item">
                <a
                  className={`nav-link ${
                    activeTab === "pills-komisi" ? "active" : ""
                  }`}
                  data-toggle="pill"
                  href="#pills-komisi"
                  role="tab"
                  onClick={() => handleTabClick("pills-komisi")}
                >
                  Komisi Sales
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="tab-content">
          <div
            className={`tab-pane fade show ${
              activeTab === "pills-deskripsi" ? "active" : ""
            }`}
            id="pills-deskripsi"
            role="tabpanel"
          >
            <div className="card detail-item-card">
              <div className="card-body">{menu?.description}</div>
            </div>
          </div>

          <div
            className={`tab-pane fade show ${
              activeTab === "pills-spesifikasi" ? "active" : ""
            }`}
            id="pills-spesifikasi"
            role="tabpanel"
          >
            <div className="card detail-item-card">
              <div className="card-body">
                <table className="table table-spesifikasi">
                  <tbody>
                    <tr>
                      <th>SKU</th>
                      <td width="10px">:</td>
                      <td>{menu?.sku || "-"}</td>
                    </tr>
                    <tr>
                      <th>Merk</th>
                      <td width="10px">:</td>
                      <td>{menu?.merk || "-"}</td>
                    </tr>
                    <tr>
                      <th>Kemasan</th>
                      <td>:</td>
                      <td>{menu?.kemasan || "-"}</td>
                    </tr>
                    <tr>
                      <th>Berat</th>
                      <td>:</td>
                      <td>
                        {menu?.weight?.value
                          ? `${menu.weight.value} ${menu.weight.unit}`
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Dimensi</th>
                      <td>:</td>
                      <td>
                        {menu?.dimensions
                          ? `${menu.dimensions.length} x ${menu.dimensions.width} x ${menu.dimensions.height}`
                          : "-"}
                      </td>
                    </tr>
                    <tr>
                      <th>Volume</th>
                      <td>:</td>
                      <td>{menu?.volume || "-"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div
            className={`tab-pane fade show ${
              activeTab === "pills-diskon" ? "active" : ""
            }`}
            id="pills-diskon"
            role="tabpanel"
          >
            <div className="card detail-item-card">
              <div className="card-body">
                <ul className="list-group list-group-flush">
                  {menu?.discount &&
                    menu?.discount.map((discount, index) => (
                      <li key={index} className="list-group-item">
                        {discount.type === "price" ? (
                          <>
                            Diskon <b>{formatPrice(discount.value)}</b> untuk
                            pembelian minimal <b>{discount.quantity}</b> item
                          </>
                        ) : (
                          <>
                            Diskon <b>{discount.value}%</b> untuk pembelian
                            minimal <b>{discount.quantity}</b> item
                          </>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <div
            className={`tab-pane fade show ${
              activeTab === "pills-komisi" ? "active" : ""
            }`}
            id="pills-komisi"
            role="tabpanel"
          >
            <div className="card detail-item-card">
              <div className="card-body">
                {menu?.sales_commision > 0 ? (
                  <p>
                    Komisi sales sebesar <b>{menu?.sales_commision}% </b>
                    diberikan untuk setiap penjualan.
                  </p>
                ) : (
                  <p>Tidak ada komisi sales untuk produk ini.</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailMenu;
