import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import PageHeader from "../../../components/PageHeader";
import CategoryTitle from "../../../components/item/CategoryTitle";
import PaymentCreditForm from "./PaymentCreditForm";

const PaymentCredit = () => {
  const { order_id, payment_id } = useParams();
  const navigate = useNavigate();
  const onBackButtonClick = () => {
    navigate(`/history/detail/${order_id}`);
  };

  return (
    <>
      <PageHeader title={"Pembayaran"} onBackButtonClick={onBackButtonClick} />

      <div className="tf-container" style={{ margin: "80px auto 100px auto" }}>
        <div className="row">
          <div className="col-md-12 mb-3">
            <div className="card">
              <div className="card-body">
                <CategoryTitle categoryTitle={"Data Pembayaran"} />

                <PaymentCreditForm
                  order_id={order_id}
                  payment_id={payment_id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentCredit;
