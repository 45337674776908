import { UPDATE_SEARCH_DATA } from "../actions/searchActions";

// src/redux/reducers/searchReducer.js
const initialState = {
  query: "",
  results: [],
  menus: [],
};

const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_DATA:
      console.log(action.payload);
      return {
        ...state,
        query: action.payload.query,
        results: action.payload.results,
        menus: action.payload.menus,
      };

    default:
      return state;
  }
};

export default searchReducer;
