import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PaymentStatus from "./PaymentStatus";
import PaymentHistory from "./PaymentHistory";

import OrderMenu from "./OrderMenu";
import SalesCommission from "./SalesCommission";
import CustomerDetail from "./CustomerDetail";
import { formattedDate } from "../../../helpers/AppHelpers";
import OrderStatus from "./orderStatus";

const DetailPesanan = () => {
  const { order_id } = useParams();
  const [order, setOrder] = useState({});
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("pills-detail-order");

  const handleTabClick = (tabId, event) => {
    event.preventDefault();
    setActiveTab(tabId);
  };

  useEffect(() => {
    // Mendapatkan hash dari URL
    const hash = window.location.hash;

    // Mengupdate activeTab sesuai dengan hash URL jika ada
    if (hash) {
      setActiveTab(hash.slice(1)); // Menghapus karakter "#" dari hash
    }
  }, []);

  const navigate = useNavigate();
  const onBackButtonClick = () => {
    navigate("/history");
  };

  const [user, setUser] = useState(null);
  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    setUser(storedUser);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        // Menggunakan Promise.all untuk menunggu kedua promise selesai
        const [orderResponse, orderPaymentResponse] = await Promise.all([
          axios.get(
            `${process.env.REACT_APP_API_URL}/commerce/order/${order_id}`
          ),
          axios.get(
            `${process.env.REACT_APP_API_URL}/commerce/order/${order_id}/payment`
          ),
        ]);

        setOrder(orderResponse.data.data);
        setOrderPayment(orderPaymentResponse.data.data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [order_id]);

  const [orderPayment, setOrderPayment] = useState({});

  return (
    <>
      <PageHeader
        title={"Detail Pesanan"}
        onBackButtonClick={onBackButtonClick}
      />

      <div className="tf-container" style={{ margin: "80px auto  100px auto" }}>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <div className="card card-detail card-detail-top mb-4">
              <div className="card-header">
                <div className="d-flex justify-content-between">
                  <h5 className="card-title">#{order.tenant_name}</h5>
                  <div className="date">{formattedDate(order.createdAt)}</div>
                </div>
                <div className="my-1">ID: {order.order_id}</div>
              </div>
              <div className="card-footer">
                <ul
                  className="nav nav-pills detail-nav"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "pills-detail-order" ? "active" : ""
                      }`}
                      data-toggle="pill"
                      href="#pills-detail-order"
                      role="tab"
                      onClick={(event) =>
                        handleTabClick("pills-detail-order", event)
                      }
                    >
                      Detail Pesanan
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "pills-riwayat-pembayaran" ? "active" : ""
                      }`}
                      data-toggle="pill"
                      href="#pills-riwayat-pembayaran"
                      role="tab"
                      onClick={(event) =>
                        handleTabClick("pills-riwayat-pembayaran", event)
                      }
                    >
                      Detail Pembayaran
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className={`nav-link ${
                        activeTab === "pills-customer" ? "active" : ""
                      }`}
                      data-toggle="pill"
                      href="#pills-customer"
                      role="tab"
                      onClick={(event) =>
                        handleTabClick("pills-customer", event)
                      }
                    >
                      Detail Customer
                    </a>
                  </li>

                  {order.sales_id && order.sales_id === user.user_id && (
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === "pills-komisi" ? "active" : ""
                        }`}
                        data-toggle="pill"
                        href="#pills-komisi"
                        role="tab"
                        onClick={(event) =>
                          handleTabClick("pills-komisi", event)
                        }
                      >
                        Komisi Penjualan
                      </a>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="tab-content">
              <div
                className={`tab-pane fade show ${
                  activeTab === "pills-detail-order" ? "active" : ""
                }`}
                id="pills-detail-order"
                role="tabpanel"
              >
                <OrderStatus order={order} />
                <OrderMenu order={order} orderPayment={orderPayment} />
              </div>

              <div
                className={`tab-pane fade show ${
                  activeTab === "pills-customer" ? "active" : ""
                }`}
                id="pills-customer"
                role="tabpanel"
              >
                <CustomerDetail order={order} />
              </div>

              <div
                className={`tab-pane fade show ${
                  activeTab === "pills-riwayat-pembayaran" ? "active" : ""
                }`}
                id="pills-riwayat-pembayaran"
                role="tabpanel"
              >
                {/* Status Pembayaran */}
                <PaymentStatus orderPayment={orderPayment} />

                {/* Payment History */}
                {orderPayment.history.length > 0 && (
                  <PaymentHistory orderPayment={orderPayment} />
                )}
              </div>

              <div
                className={`tab-pane fade show ${
                  activeTab === "pills-komisi" ? "active" : ""
                }`}
                id="pills-komisi"
                role="tabpanel"
              >
                <SalesCommission order={order} user={user} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DetailPesanan;
