// App.js
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastProvider } from "react-toast-notifications";
import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux"; // Import Provider
import PrivateRoute from "./components/PrivateRoute";
import Home from "./pages/home";
import Login from "./pages/auth/login";
import OtpVerification from "./pages/auth/otp_verification";
import store from "./redux/store"; // Import your Redux store

import "./assets/css/style.css";
import Cart from "./pages/cart";
import Order from "./pages/order";
import OrderSuccess from "./pages/order/OrderSuccess";
import History from "./pages/history";
import DetailPesanan from "./pages/history/detail";
import Payment from "./pages/payment";
import PaymentCredit from "./pages/payment/credit";
import Profile from "./pages/profile";
import RegisterSales from "./pages/registerSales";
import PaymentSuccess from "./pages/payment/PaymentSuccess";
import DetailMenu from "./pages/detailMenu";
import EditProfile from "./pages/profile/EditProfile";

const App = () => {
  return (
    <Provider store={store}>
      {/* Wrap your components with Provider */}
      <ToastProvider>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/login/otp" element={<OtpVerification />} />

          <Route path="/" element={<PrivateRoute element={<Home />} />} />
          <Route
            path="/menu/:menu_id"
            element={<PrivateRoute element={<DetailMenu />} />}
          />
          <Route path="/cart" element={<PrivateRoute element={<Cart />} />} />
          <Route
            path="/order/create"
            element={<PrivateRoute element={<Order />} />}
          />

          <Route
            path="/order/success"
            element={<PrivateRoute element={<OrderSuccess />} />}
          />

          <Route
            path="/history"
            element={<PrivateRoute element={<History />} />}
          />

          <Route
            path="/history/detail/:order_id"
            element={<PrivateRoute element={<DetailPesanan />} />}
          />

          <Route
            path="/payment/:order_id"
            element={<PrivateRoute element={<Payment />} />}
          />

          <Route
            path="/payment/:order_id/:payment_id"
            element={<PrivateRoute element={<PaymentCredit />} />}
          />

          <Route
            path="/payment/:order_id/status/update/:payment_id"
            element={<PrivateRoute element={<PaymentSuccess />} />}
          />

          <Route
            path="/profile"
            element={<PrivateRoute element={<Profile />} />}
          />
          <Route
            path="/profile/edit/:user_id"
            element={<PrivateRoute element={<EditProfile />} />}
          />

          <Route
            path="/register/sales"
            element={<PrivateRoute element={<RegisterSales />} />}
          />
        </Routes>
      </ToastProvider>
    </Provider>
  );
};

export default App;
