// components/item/CardItemCart.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  calculateMenuDiscount,
  formatPrice,
  isActiveMenu,
} from "../../helpers/AppHelpers";
import { updateCart } from "../../redux/actions/cartActions";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import DisabledProduct from "../../components/DisabledProduct";

const CardItemCart = ({ id, qty, onClick }) => {
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const [menu, setMenu] = useState(null);
  const [quantity, setQuantity] = useState(qty);
  const carts = useSelector((state) => state.cart.carts);

  const discount = calculateMenuDiscount(menu, quantity);
  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/commerce/menu/tenant/${process.env.REACT_APP_TENANT_ID}/menus/${id}`
        );
        setMenu(response.data.data);
      } catch (error) {
        console.error("Error fetching menu data:", error);
        removeFromCart();
      }
    };

    if (id) {
      fetchMenuData();
    }
  }, [id]);

  const removeFromCart = () => {
    const updatedCarts = carts.filter((item) => item.id !== id);
    dispatch(updateCart(updatedCarts));
  };

  const increaseCartQty = () => {
    const newQty = quantity + 1;

    if (menu && menu.quantity < newQty && !menu.isUnlimited) {
      addToast(`Sisa stok hanya ${menu.quantity}`, {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setQuantity(newQty);
    updateCartQty(newQty);
  };

  const decreaseCartQty = () => {
    const newQty = quantity - 1;
    setQuantity(newQty);

    if (newQty <= 0) {
      removeFromCart();
      addToast(`Berhasil menghapus ${menu.name} dari keranjang`, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      updateCartQty(newQty);
    }
  };

  const updateCartQty = (qty) => {
    let updatedCarts = carts.map((item) =>
      item.id === menu.id ? { ...item, qty: qty } : item
    );
    dispatch(updateCart(updatedCarts));
  };

  const setCartQty = (event) => {
    const newQty = event.target.value.replace(/\D/g, "");
    const numericValue = parseInt(newQty, 10);

    if (isNaN(numericValue)) {
      setQuantity(0);
      updateCartQty(0);
      return;
    }

    if (menu && menu.quantity < numericValue && !menu.isUnlimited) {
      addToast(`Sisa stok hanya ${menu.quantity}`, {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    setQuantity(numericValue);
    updateCartQty(numericValue);
  };

  if (!menu) {
    return null; // Menampilkan loading atau pesan error sesuai kebutuhan
  }

  return isActiveMenu(menu) ? (
    <div className="product-item">
      <div className="product-image">
        <img src={menu.menuImage} alt="" />
      </div>

      <div className="product-info">
        <h4 className="title" onClick={() => onClick(menu.id)}>
          {menu.name}
        </h4>
        <div className="detail-container">
          <div className="price">
            {discount === 0 ? (
              formatPrice(menu.price)
            ) : (
              <>
                {formatPrice(menu.price - discount)}
                <span className="discount">{formatPrice(menu.price)}</span>
              </>
            )}
          </div>
        </div>
        <div className="d-flex">
          <div className="action-container flex-grow-1">
            <button className="btn btn-control-cart" onClick={decreaseCartQty}>
              -
            </button>
            <input
              type="text"
              value={quantity}
              onChange={setCartQty}
              className="form-control form-control-sm"
              min={0}
            />
            <button className="btn btn-control-cart" onClick={increaseCartQty}>
              +
            </button>
          </div>
          <button
            className="btn d-flex flex-row justify-content-end flex-grow-1 mt-1"
            onClick={removeFromCart}
          >
            <i className="bx bx-trash text-danger"></i>
          </button>
        </div>
      </div>
    </div>
  ) : (
    <DisabledProduct menu={menu} />
  );
};

export default CardItemCart;
