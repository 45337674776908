import React from "react";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import FormRegisterSales from "./FormRegisterSales";

const RegisterSales = () => {
  const navigate = useNavigate();
  const onBackButtonClick = () => {
    navigate(`/profile`);
  };
  return (
    <>
      <PageHeader
        title={"Pendaftaran Sales"}
        onBackButtonClick={onBackButtonClick}
      />

      <div className="tf-container" style={{ margin: "80px auto 100px auto" }}>
        <FormRegisterSales />
      </div>
    </>
  );
};

export default RegisterSales;
