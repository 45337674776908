import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const CustomerDetail = ({ order }) => {
  const [isOpenImageLocation, setIsOpenImageLocation] = useState(false);

  const openLightbox = () => {
    setIsOpenImageLocation(true);
  };

  const renderImages = () => {
    return [
      {
        src: `${process.env.REACT_APP_API_URL}/${order.user_info.address.image}`,
        alt: `Foto Lokasi`,
      },
    ];
  };

  return (
    <>
      <div className="card card-detail mb-4">
        <div className="card-body">
          <h5 className="title">Data Customer</h5>
          <table className="table table-item-list">
            <tbody>
              <tr>
                <td className="name ">Nama</td>
                <td className="total ">{order.user_info.name}</td>
              </tr>
              <tr>
                <td className="name ">Telepon</td>
                <td className="total ">{order.user_info.phone_number}</td>
              </tr>

              <tr>
                <td className="name ">Alamat</td>
                <td className="total ">{order.user_info.address.address}</td>
              </tr>

              <tr>
                <td className="name ">Lokasi</td>
                <td className="total ">
                  <button
                    className="btn btn-see-receipt"
                    onClick={() =>
                      window.open(order.user_info.address.gmaps_link, "_blank")
                    }
                  >
                    Buka Maps
                  </button>
                </td>
              </tr>

              <tr>
                <td className="name ">Foto Lokasi</td>
                <td className="total ">
                  <button
                    className="btn btn-see-receipt"
                    onClick={() => openLightbox()}
                  >
                    Lihat Foto Lokasi
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {isOpenImageLocation && (
        <Lightbox
          mainSrc={renderImages()[0].src}
          onCloseRequest={() => setIsOpenImageLocation(false)}
          images={renderImages()}
          startIndex={0}
        />
      )}
    </>
  );
};

export default CustomerDetail;
