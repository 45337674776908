import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";

const OrderForm = ({
  formData,
  errors,
  setFormData,
  setErrors,
  user,
  addresses,
  setAddresses,
}) => {
  const videoRef = useRef(null);
  const [isCameraActive, setIsCameraActive] = useState(false);
  const [capturedImage, setCaturedImage] = useState(null);
  const [isNewAddress, setIsNewAddress] = useState(addresses <= 0);

  const [isNewCustomer, setIsNewCustomer] = useState(false);

  useEffect(() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/user/retrieve/phone/:phone_number`;

    const phoneNumber = formData.customer_phone; // Replace with your logic to get the phone number

    const fetchData = async () => {
      try {
        const response = await axios.get(
          apiUrl.replace(":phone_number", phoneNumber)
        );
        // setCustomer(response.data.data);
        const data = response.data.data;
        setFormData({
          ...formData,
          customer_name: data.name,
        });
        setAddresses(data.addresses);
        setIsNewCustomer(false);
      } catch (error) {
        setFormData({
          ...formData,
          customer_name: "",
        });
        setAddresses([]);
        setIsNewCustomer(true);
      }
    };

    let timeoutId;

    if (formData.is_sales && formData.customer_phone) {
      timeoutId = setTimeout(() => {
        fetchData();
      }, 500);
    }

    return () => clearTimeout(timeoutId);
  }, [formData.customer_phone]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "customer_phone" && value && !value.startsWith("+62")) {
      setFormData({ ...formData, [name]: `+62${value}` });
    } else {
      setFormData({ ...formData, [name]: value });
    }
    // Clear errors when the user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };

  useEffect(() => {
    if (addresses?.length <= 0) {
      setIsNewAddress(true);
    } else {
      setIsNewAddress(false);
    }
  }, [addresses]);

  const handleIsSalesOrderChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
      customer_name: "",
      customer_phone: "+62",
    });

    if (checked) {
      setAddresses([]);
    } else {
      setAddresses(user.addresses);
    }
    setCaturedImage(null);
  };

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setCaturedImage(null);
    if (value === "") {
      setFormData({
        ...formData,
        address_id: "",
        address: "",
        gmaps_link: "",
      });
      setIsNewAddress(true);
    } else {
      const selected_address = addresses?.find((item) => item.id === value);
      setFormData({
        ...formData,

        address_id: selected_address.id,
        address: selected_address.address,
        gmaps_link: selected_address.gmaps_link,
      });
      setIsNewAddress(false);
    }

    // Clear errors when the user starts typing
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };

  const handleFileChange = (e) => {
    const fileInput = e.target;
    const file = fileInput.files[0];

    if (fileInput.capture && fileInput.capture === "user") {
      // Pengguna memilih untuk menggunakan kamera pengguna
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result;

        // Mengatur data formulir dan gambar yang ditangkap
        setFormData({ ...formData, location_image: file });
        setCaturedImage(base64Data);
      };

      if (file) {
        // Memulai membaca file
        reader.readAsDataURL(file);
      }
    } else {
      // Pengguna tidak memilih kamera pengguna
      alert("Gunakan tombol kamera untuk mengambil foto langsung.");
      fileInput.value = ""; // Menghapus file yang dipilih (jika ada)
    }
  };

  const handleCapture = (facingMode) => {
    setCaturedImage(null);
    setIsCameraActive(true);

    const constraints = {
      video: { facingMode: facingMode },
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          videoRef.current.srcObject = stream;
        })
        .catch((error) => {
          console.error("Error accessing camera:", error);
        });
    }
  };

  const handleSnapshot = () => {
    const video = videoRef.current;
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const context = canvas.getContext("2d");
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const dataURL = canvas.toDataURL("image/jpeg");
    setCaturedImage(dataURL);
    // Mengambil blob dari canvas
    canvas.toBlob((blob) => {
      // Membuat objek File dari blob
      const imageFile = new File([blob], "captured-image.jpg", {
        type: "image/jpeg",
        lastModified: Date.now(),
      });

      // Menyimpan file gambar ke dalam state atau melakukan hal lainnya
      setFormData({ ...formData, location_image: imageFile });

      setIsCameraActive(false);

      // Stop the camera stream
      const tracks = video.srcObject.getTracks();
      tracks.forEach((track) => track.stop());
      // Lakukan sesuatu dengan file gambar (misalnya, kirim ke server, tampilkan, dll.)
    }, "image/jpeg");
  };

  const stopCamera = () => {
    try {
      const video = videoRef.current;
      setIsCameraActive(false);

      if (video && video.srcObject) {
        const tracks = video.srcObject.getTracks();
        tracks.forEach((track) => track.stop());
      }
    } catch (error) {
      console.error("Error stopping camera:", error);
    }
  };

  const extractUrlFromString = (inputString) => {
    const urlRegex = /(https?:\/\/[^\s]+)/;
    const match = inputString.match(urlRegex);
    return match ? match[0] : "";
  };

  const handleGmapsLinkChange = (e) => {
    const { name, value } = e.target;
    const extractedUrl = extractUrlFromString(value);
    setFormData({ ...formData, [name]: `${extractedUrl}` });
    setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
  };

  return (
    <form>
      <div className="row">
        <div className="col-md-12">
          <div className="form-group mb-3">
            <label htmlFor="user_phone">Nomor Telepon Pemesan</label>
            <input
              type="text"
              className={`form-control ${
                errors.user_phone ? "is-invalid" : ""
              }`}
              id="user_phone"
              name="user_phone"
              value={formData.user_phone}
              disabled={true}
            />
            {errors.user_phone && (
              <div className="invalid-feedback">{errors.user_phone}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <label htmlFor="name">Nama Pemesan</label>
            <input
              type="text"
              className={`form-control ${errors.user_name ? "is-invalid" : ""}`}
              id="user_name"
              name="user_name"
              value={formData.user_name}
              onChange={handleChange}
            />
            {errors.user_name && (
              <div className="invalid-feedback">{errors.user_name}</div>
            )}
          </div>

          <div className="form-group mb-3">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                name="is_sales"
                value={formData.is_sales}
                id="is_sales"
                checked={formData.is_sales}
                onChange={handleIsSalesOrderChange}
              />
              <label className="form-check-label" htmlFor="is_sales">
                Pesankan untuk Pelanggan
              </label>
            </div>
          </div>

          {formData.is_sales && (
            <>
              <div className="form-group mb-3">
                <label htmlFor="customer_phone">Nomor Telepon Pelanggan</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.customer_phone ? "is-invalid" : ""
                  }`}
                  id="customer_phone"
                  name="customer_phone"
                  value={formData.customer_phone}
                  onChange={handleChange}
                />
                {errors.customer_phone && (
                  <div className="invalid-feedback">
                    {errors.customer_phone}
                  </div>
                )}

                {isNewCustomer && formData.customer_phone !== "" && (
                  <div className="text-info invalid-feedback d-block">
                    Pelanggan belum terdaftar di database oasis. Silakan
                    lengkapi data Pelanggan secara manual.
                  </div>
                )}
              </div>

              <div className="form-group mb-3">
                <label htmlFor="name">Nama Pelanggan</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.customer_name ? "is-invalid" : ""
                  }`}
                  id="customer_name"
                  name="customer_name"
                  value={formData.customer_name}
                  onChange={handleChange}
                />
                {errors.customer_name && (
                  <div className="invalid-feedback">{errors.customer_name}</div>
                )}
              </div>
            </>
          )}

          <div className="form-group mb-3">
            <label htmlFor="shippingAddress">Pilih Alamat Pengiriman</label>

            <select
              className={`form-control `}
              value={formData.address_id}
              onChange={handleAddressChange}
            >
              <option value="">Kirim ke alamat baru</option>
              {addresses?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.address}
                </option>
              ))}
            </select>
          </div>

          <div className={isNewAddress ? "" : "d-none"}>
            <div className="form-group mb-3">
              <label htmlFor="address">Alamat </label>
              <textarea
                className={`form-control ${errors.address ? "is-invalid" : ""}`}
                id="address"
                name="address"
                value={formData.address}
                onChange={handleChange}
              ></textarea>
              {errors.address && (
                <div className="invalid-feedback">{errors.address}</div>
              )}
            </div>

            <div className="form-group mb-3">
              <label htmlFor="gmaps_link">Link Google Maps</label>
              <input
                type="text"
                className={`form-control ${
                  errors.gmaps_link ? "is-invalid" : ""
                }`}
                id="gmaps_link"
                name="gmaps_link"
                value={formData.gmaps_link}
                onChange={handleGmapsLinkChange}
              />
              {errors.gmaps_link && (
                <div className="invalid-feedback">{errors.gmaps_link}</div>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-12">
          {!isMobile && (
            <div className="form-group mb-3">
              <label htmlFor="location_image">Foto Lokasi</label>
              <div>
                {isCameraActive ? (
                  <div>
                    <video ref={videoRef} style={{ width: "100%" }} autoPlay />
                    <div className="d-flex my-3 w-100">
                      <button
                        className="btn btn-take-photo mr-2 take"
                        type="button"
                        onClick={handleSnapshot}
                      >
                        Ambil Foto
                      </button>

                      <button
                        className="btn btn-take-photo close-camera"
                        type="button"
                        onClick={stopCamera}
                      >
                        Tutup
                      </button>
                    </div>
                  </div>
                ) : (
                  <button
                    className="btn btn-take-photo open"
                    type="button"
                    onClick={handleCapture}
                  >
                    Buka Kamera
                  </button>
                )}
                {errors.location_image && (
                  <div className="invalid-feedback d-block">
                    {errors.location_image}
                  </div>
                )}
              </div>
              {capturedImage && (
                <img
                  src={capturedImage}
                  className="captured-image"
                  alt="Location"
                />
              )}
            </div>
          )}

          {isMobile && (
            <div className="form-group mb-3">
              <label htmlFor="location_image">Foto Lokasi</label>
              <input
                type="file"
                accept="image/*"
                capture="user"
                className={`form-control ${
                  errors.location_image ? "is-invalid" : ""
                }`}
                id="location_image"
                name="location_image"
                onChange={handleFileChange}
              />
              {capturedImage && (
                <img
                  src={capturedImage}
                  className="captured-image"
                  alt="Location"
                  style={{ width: "100%" }}
                />
              )}
              {errors.location_image && (
                <div className="invalid-feedback">{errors.location_image}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

// Add a static function to validate the form
OrderForm.validateForm = (formData, setErrors) => {
  const isPhoneNumberValid = (phoneNumber) => {
    const phoneNumberRegex = /^\+62\d{9,15}$/;
    return phoneNumberRegex.test(phoneNumber);
  };

  const newErrors = {};

  if (!formData.user_name.trim()) {
    newErrors.user_name = "Nama Pemesan harus diisi";
  }

  if (formData.is_sales) {
    if (!formData.customer_name.trim()) {
      newErrors.customer_name = "Nama Pelanggan harus diisi";
    }

    if (!formData.customer_phone.trim()) {
      newErrors.customer_phone = "Nomor Telepon Pelanggan harus diisi";
    } else if (!isPhoneNumberValid(formData.customer_phone)) {
      newErrors.customer_phone =
        "Nomor Telepon tidak valid. Harus diawali dengan +62 dan minimal 10 digit";
    }

    // Check if customer_phone is the same as user_phone
    if (formData.customer_phone.trim() === formData.user_phone.trim()) {
      newErrors.customer_phone =
        "Nomor Telepon Pelanggan tidak boleh sama dengan Nomor Telepon Pemesan";
    }
  }

  if (!formData.user_phone.trim()) {
    newErrors.user_phone = "Nomor Telepon Pemesan harus diisi";
  } else if (!isPhoneNumberValid(formData.user_phone)) {
    newErrors.user_phone =
      "Nomor Telepon tidak valid. Harus diawali dengan +62 dan minimal 10 digit";
  }

  if (!formData.address.trim()) {
    newErrors.address = "Alamat Pengiriman harus diisi";
  }

  if (!formData.gmaps_link.trim()) {
    newErrors.gmaps_link = "Link Google Maps harus diisi";
  }

  if (!formData.location_image || formData.location_image == null) {
    console.log("LOaction: ", formData.location_image);
    newErrors.location_image = "Foto Lokasi harus diunggah";
  }

  setErrors(newErrors);
  return Object.keys(newErrors).length === 0; // Return true if there are no errors
};

export default OrderForm;
