import React from "react";
import { formatPrice } from "../../../helpers/AppHelpers";
import PaymentStatusBadge from "../../../components/PaymentStatusBadge";
import { useNavigate } from "react-router-dom";

const PaymentStatus = ({ orderPayment }) => {
  let payment_status = orderPayment.status;
  if (orderPayment.history.length > 0 && !payment_status) {
    payment_status = orderPayment.history[0].status;
  }

  const navigate = useNavigate();
  return (
    <div className="card card-detail mb-4">
      <div className="card-body">
        <h5 className="title">Status Pembayaran</h5>
        <table className="table table-item-list">
          <tbody>
            <tr>
              <td className="name ">Status</td>
              <td className="total ">
                <PaymentStatusBadge status={payment_status} />
              </td>
            </tr>

            <tr>
              <td className="name ">Terbayar</td>
              <td className="total">{formatPrice(orderPayment.paid_amount)}</td>
            </tr>

            <tr>
              <td className="name ">Sisa Tagihan</td>
              <td className="total text-danger">
                {formatPrice(orderPayment.remaining_payment)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PaymentStatus;
