// src/redux/actions/categoryActions.js
import axios from "axios";
import { fetchSubCategories } from "./subCategoryActions";

// Action Types
export const UPDATE_PARENT_CATEGORY = "UPDATE_PARENT_CATEGORY";

// Action Creators

export const updateParentCategory = (categories) => ({
  type: UPDATE_PARENT_CATEGORY,
  payload: categories,
});

// Thunk to fetch parent categories
export const fetchParentCategories = (parent_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/commerce/menu/tenant/${process.env.REACT_APP_TENANT_ID}/parent-category`
    );
    const categories = response.data.data;

    dispatch(updateParentCategory(categories));
    dispatch(
      fetchSubCategories(parent_id === "all" ? categories[0].id : parent_id)
    );
  } catch (error) {
    console.error("Error fetching parent categories:", error);
  }
};
