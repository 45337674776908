import React, { useEffect, useState } from "react";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { formatPrice } from "../../helpers/AppHelpers";
import { RingLoader } from "react-spinners";

const InfoItem = ({ type, amount, text }) => (
  <div className="info-item">
    <div className={`content ${type}`}>{amount}</div>
    <div className="title">{text}</div>
  </div>
);

const Profile = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [commission, setCommission] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUser = JSON.parse(localStorage.getItem("user"));

        if (storedUser) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/commerce/sales/${storedUser.user_id}/commission`
          );
          const { data } = response;
          setUser(storedUser);
          setCommission(data.data);
          setLoading(false); // Set loading to false once data is fetched
        } else {
          // Handle the case when there is no user data in local storage
          setLoading(false); // Set loading to false in case of an error
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false in case of an error
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <PageHeader title={"Profil"} />
      <div className="tf-container" style={{ margin: "80px auto 100px auto" }}>
        {loading ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "60vh", // Adjust this based on your design
            }}
          >
            <RingLoader size={50} color={"#d83f31"} loading={loading} />
          </div>
        ) : (
          <div className="card card-user">
            <div className="header-container">
              <img
                className="card-img-top"
                src="https://images.unsplash.com/photo-1565891741441-64926e441838?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                alt="Card image cap"
              ></img>
              <div className="avatar-container">
                <img src="/user-profile.svg" alt="User Profile" />
              </div>
            </div>
            <div className="card-body">
              <h4 className="card-title">{user?.name}</h4>
              <div className="sub-title">{user?.phoneNumber}</div>
              <button
                className="btn btn-edit btn-danger btn-sm"
                onClick={() => {
                  navigate(`/profile/edit/${user?.user_id}`);
                }}
              >
                Edit
              </button>
            </div>
            <div className="card-footer">
              <div className="row">
                <div className="col-md-6">
                  <InfoItem
                    amount={formatPrice(commission?.total_balance)}
                    text="Total Komisi"
                    type={"success"}
                  />
                </div>
                <div className="col-md-6">
                  <InfoItem
                    type={"warning"}
                    amount={formatPrice(commission?.pending_balance)}
                    text="Pending Komisi"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Profile;
