// Home.js
import React from "react";
import PageHeader from "../../../components/PageHeader";
import OtpForm from "./OtpForm";

const Login = () => {
  return (
    <>
      <PageHeader title={"Masuk Oasis Sales"} />
      <OtpForm />
    </>
  );
};

export default Login;
