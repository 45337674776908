import React, { useEffect, useState } from "react";
import axios from "axios";
import { css } from "@emotion/react";
import { RingLoader } from "react-spinners";
import OrderCard from "./OrderCard";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";

const History = () => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [userOrders, setUserOrders] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const [loading, setLoading] = useState(true);

  const onBackButtonClick = () => {
    navigate(`/`);
  };

  const handleClick = (orderId) => {
    navigate(`detail/${orderId}`);
  };

  const fetchData = async (page) => {
    try {
      const user = JSON.parse(localStorage.getItem("user"));

      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/commerce/order/user/${user.user_id}?page=${page}`
      );
      setUserOrders(response.data.data);
      setCurrentPage(page);
      setPageInfo(response.data.info);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching orders:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchData(currentPage);
  }, [currentPage]);

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const loadingContainerStyles = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "60vh", // Adjust this based on your design
  };

  const renderPageNumbers = () => {
    const totalPages = pageInfo.totalPages;
    const visiblePageNumbers = [];
    const range = 3; // Number of visible page numbers on each side of the current page

    for (
      let i = Math.max(1, currentPage - range);
      i <= Math.min(totalPages, currentPage + range);
      i++
    ) {
      visiblePageNumbers.push(i);
    }

    return visiblePageNumbers.map((pageNumber, index) => (
      <li
        key={index}
        className={`page-item ${pageNumber === currentPage ? "active" : ""}`}
      >
        <button
          className="page-link"
          onClick={() => handlePageChange(pageNumber)}
        >
          {pageNumber}
        </button>
      </li>
    ));
  };

  return (
    <>
      <PageHeader
        title={"Riwayat Pesanan"}
        onBackButtonClick={onBackButtonClick}
      />
      <div className="tf-container" style={{ margin: "80px auto 100px auto" }}>
        {loading ? (
          <div style={loadingContainerStyles}>
            <RingLoader size={50} color={"#d83f31"} loading={loading} />
          </div>
        ) : userOrders.length <= 0 ? (
          <div className="alert-container default">
            <i className="bx bx-store-alt"></i>
            <h4 className="title">Riwayat Transaksi Kosong</h4>
            <p className="desc">
              Silakan lakukan transaksi untuk melihat riwayat transaksi.
            </p>
          </div>
        ) : (
          <>
            {userOrders.map((order) => (
              <OrderCard
                key={order.id}
                order={order}
                onClick={() => handleClick(order.order_id)}
              />
            ))}
            <nav className="py-2">
              <ul className="pagination">
                <li
                  className={`page-item ${
                    !pageInfo.hasPrevPage ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    aria-label="Previous"
                    disabled={!pageInfo.hasPrevPage}
                  >
                    <span aria-hidden="true">&laquo;</span>
                    <span className="sr-only">Previous</span>
                  </button>
                </li>
                {currentPage > 4 && (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
                {renderPageNumbers()}
                {currentPage < pageInfo.totalPages - 3 && (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}
                <li
                  className={`page-item ${
                    !pageInfo.hasNextPage ? "disabled" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    aria-label="Next"
                    disabled={!pageInfo.hasNextPage}
                  >
                    <span aria-hidden="true">&raquo;</span>
                    <span className="sr-only">Next</span>
                  </button>
                </li>
              </ul>
            </nav>
          </>
        )}
      </div>
    </>
  );
};

export default History;
