// src/redux/actions/categoryActions.js
import axios from "axios";

// Action Types
export const UPDATE_SUB_CATEGORY = "UPDATE_SUB_CATEGORY";

// Action Creators

export const updateSubCategory = (subCategories) => ({
  type: UPDATE_SUB_CATEGORY,
  payload: subCategories,
});

// Thunk to fetch parent categories
export const fetchSubCategories = (parent_id) => async (dispatch) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/commerce/menu/tenant/${process.env.REACT_APP_TENANT_ID}/parent-category/${parent_id}`
    );
    const subCategories = response.data.data.subCategories;

    const data = [];
    // Add is_selected property to the first item
    if (subCategories.length > 0) {
      subCategories.forEach((val, key) => {
        const item = {
          id: val.id,
          name: val.name,
          menus: val.menu,
        };
        data.push(item);
      });
    }

    dispatch(updateSubCategory(data));
  } catch (error) {
    console.error("Error fetching parent categories:", error);
  }
};
