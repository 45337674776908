// src/redux/reducers/categoryReducer.js
import { UPDATE_PARENT_CATEGORY } from "../actions/categoryActions";
import { UPDATE_SUB_CATEGORY } from "../actions/subCategoryActions";

const initialState = {
  parentCategories: [],
  subCategories: [],
};

const categoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PARENT_CATEGORY:
      return {
        ...state,
        parentCategories: action.payload,
      };

    // Sub Categories
    case UPDATE_SUB_CATEGORY:
      return {
        ...state,
        subCategories: action.payload,
      };

    default:
      return state;
  }
};

export default categoryReducer;
