import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const PromoCarousel = () => {
  const promoImageFilenames = [
    "banner-1.jpeg",
    "banner-2.jpeg",
    "banner-3.jpeg",
    "banner-4.jpeg",
    "banner-5.jpeg",
  ];

  const options = {
    stagePadding: 30,
    loop: true,
    margin: 10,
    nav: false,
    items: 1,
    autoplay: true,
    responsive: {
      768: {
        stagePadding: 50,
        margin: 20,
      },
    },
  };

  return (
    <OwlCarousel className="promo mb-lg-4 owl-carousel" {...options}>
      {promoImageFilenames.map((filename, index) => (
        <div key={index} className="item">
          {/* Gunakan URL relatif untuk merujuk ke gambar di folder public */}
          <img className="promo-image" src={`./banner/${filename}`} alt="" />
        </div>
      ))}
    </OwlCarousel>
  );
};

export default PromoCarousel;
