import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchParentCategories } from "../../redux/actions/categoryActions";

import Select from "react-select";
import {
  fetchHomeMenu,
  updateHomeMenu,
} from "../../redux/actions/homeMenuActions";
import { fetchSubCategories } from "../../redux/actions/subCategoryActions";

const CategorySelector = ({ subCategories }) => {
  const dispatch = useDispatch();
  const parentCategories = useSelector(
    (state) => state.category.parentCategories
  );

  const home_menu = useSelector((state) => state.home_menu.home_menu);

  useEffect(() => {
    dispatch(fetchHomeMenu());
    dispatch(fetchParentCategories(home_menu.category_id));
  }, [dispatch]);

  const handleParentCategoryChange = (event) => {
    const selectedCategoryId = event.value;

    dispatch(
      updateHomeMenu({
        category_id: selectedCategoryId,
        sub_category_id: "all",
      })
    );

    dispatch(fetchSubCategories(selectedCategoryId));
  };

  const handleSubCategoryChange = (event) => {
    const selectedCategoryId = event.value;

    dispatch(
      updateHomeMenu({ ...home_menu, sub_category_id: selectedCategoryId })
    );
  };

  const parentCategoryOptions = parentCategories.map((category) => ({
    value: category.id,
    label: category.name,
  }));

  const sortedSubCategories = subCategories
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter((item) => item.menus.length > 0);

  const subCategoryOptions = [
    {
      value: "all",
      label: "Semua Kategori",
    },
    ...sortedSubCategories.map((sub_category) => ({
      value: sub_category.id,
      label: sub_category.name,
    })),
  ];

  return (
    <div className="d-flex justify-content-center">
      <Select
        className="select-category"
        classNamePrefix="select-category"
        placeholder="Pilih Kategori"
        options={parentCategoryOptions}
        value={parentCategoryOptions.find(
          (item) => item.value === home_menu.category_id
        )}
        onChange={handleParentCategoryChange}
        theme={(theme) => ({
          ...theme,
          borderRadius: 10,
          colors: {
            ...theme.colors,
            primary25: "#e89a97",
            primary: "#d83f31",
          },
        })}
      />

      <Select
        className="select-category"
        classNamePrefix="select-category"
        placeholder="Pilih Sub Kategori"
        options={subCategoryOptions}
        value={subCategoryOptions.find(
          (item) => item.value === home_menu.sub_category_id
        )}
        onChange={handleSubCategoryChange}
        theme={(theme) => ({
          ...theme,
          borderRadius: 10,
          borderWidth: 1,
          colors: {
            ...theme.colors,
            primary25: "#e89a97",
            primary: "#d83f31",
          },
        })}
      />
    </div>
  );
};

export default CategorySelector;
