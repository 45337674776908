// components/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import BottomMenu from "./BottomMenu";

const PrivateRoute = ({ element, ...rest }) => {
  const isAuthenticated = () => {
    const storedUser = localStorage.getItem("user");

    if (storedUser && storedUser !== "") {
      return true;
    }
    return false;
  };
  // const isAuthenticated = false;

  if (!isAuthenticated()) {
    // Jika tidak terotentikasi, redirect ke halaman login
    return <Navigate to="/login" />;
  }

  // Jika terotentikasi, lanjutkan dengan rendering komponen
  return (
    <>
      {element}
      <BottomMenu />
    </>
  );
};

export default PrivateRoute;
