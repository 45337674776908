import React, { useState, useEffect } from "react";
import PageHeader from "../../components/PageHeader";
import { useNavigate } from "react-router-dom";
import CategoryTitle from "../../components/item/CategoryTitle";
import DisabledProduct from "../../components/DisabledProduct";
import CardItemHome from "./CardItemHome";
import { isActiveMenu } from "../../helpers/AppHelpers";
import { RingLoader } from "react-spinners";

const SearchView = ({ search }) => {
  const navigate = useNavigate();
  const [renderResults, setRenderResults] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true); // Set loading to true when search.query changes

    const delayRender = setTimeout(() => {
      setRenderResults(search.results);
      setLoading(false); // Set loading to false once results are ready
    }, 500); // 1000 milliseconds (1 second)

    return () => clearTimeout(delayRender); // Clear the timeout on unmount or when search changes
  }, [search.query, search.results]); // Include search.query in the dependency array

  const onClick = (menu_id) => {
    navigate(`/menu/${menu_id}`);
  };

  return (
    <section className="mb-4">
      <CategoryTitle categoryTitle={`Hasil Pencarian: ${search.query}`} />
      {loading ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "60vh", // Adjust this based on your design
          }}
        >
          <RingLoader size={50} color={"#d83f31"} loading={loading} />
        </div>
      ) : (
        renderResults.length > 0 &&
        renderResults.map((menu, index) =>
          isActiveMenu(menu) ? (
            <CardItemHome key={index} menu={menu} onClick={onClick} />
          ) : (
            <DisabledProduct key={index} menu={menu} />
          )
        )
      )}
    </section>
  );
};

export default SearchView;
